import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemConfig, FormItemType } from 'components/Form/helpers';
import FormInput from 'components/Form/FormInput';
import { positionsService } from 'shared/services';
import { getErrorInfo } from 'shared/utils/error.utils';
import { ModalMode, WAIT_TIME } from 'shared/constants/common.const';
import _ from 'lodash';
import { TagItem } from 'components/TagCollection/helper';
import { generatePath, useHistory, useParams } from 'react-router';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { useCancellationModal } from 'components/Modal/CancelModal';
import { lookForChanges, showNotification } from 'shared/utils/common.utils';
import { Constants, IInterviewer, InterviewerFields } from './helper';
import { InterviewerContext } from '../../InterviewerContext';

function InterviewerModal() {
  const { mode, id } = useParams<{ mode: ModalMode; id: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = useForm<IInterviewer>();
  const [persons, setPersons] = useState<DTO.PersonReviewer[]>([]);
  const { CancellationModal, confirmCancellation } = useCancellationModal();
  const [expertise, setExpertise] = useState<TagItem[]>([]);
  const { expertiseOptions, updateExpertiseOptions } =
    useContext(InterviewerContext);
  const [isLoading, setIsLoading] = useState(false);

  const title = useMemo(() => {
    const titleDictionary: Record<ModalMode, string> = {
      [ModalMode.add]: t('common.add'),
      [ModalMode.edit]: t('common.edit'),
      [ModalMode.view]: t('common.view'),
      [ModalMode.closed]: '',
    };

    return titleDictionary[mode];
  }, [mode, t]);

  const personsOptions = useMemo(
    () =>
      persons.map((person) => ({
        name: person.fullName,
        value: person.personId,
      })),
    [persons]
  );

  const getPersons = useCallback(
    async (personName: string) => {
      try {
        const response = await positionsService.getAvailableInterviewers({
          personName,
        });

        setPersons(response.results || []);
      } catch (error) {
        getErrorInfo(error as Promise<string>, t);
      }
    },
    [t]
  );

  const isOpen = useMemo(() => !!mode, [mode]);
  const isViewing = useMemo(() => mode === ModalMode.view, [mode]);
  const isEditing = useMemo(() => mode === ModalMode.edit, [mode]);

  const emptyInterviewer = useRef<Partial<DTO.InterviewerList>>({
    [InterviewerFields.EXPERTISE]: [],
  });

  const [defaultValues, setDefaultValues] = useState<
    Partial<DTO.InterviewerList>
  >(emptyInterviewer.current);

  const body = useMemo(() => {
    const bodyTemplate = {
      ...form.getFieldsValue(),
      id: +id,
      [InterviewerFields.EXPERTISE]: expertise,
    };

    return bodyTemplate;
  }, [expertise, form, id]);

  const closeModal = useCallback(() => {
    history.push({
      pathname: generatePath(PLACEMENT_PATH.INTERVIEWERS),
      search: history.location.search,
    });
    form.resetFields();
    setDefaultValues(emptyInterviewer.current);
    setExpertise([]);
  }, [form, history]);

  const getPersonsDebounced = useMemo(
    () => _.debounce(getPersons, WAIT_TIME),
    [getPersons]
  );

  const fields: FormItemConfig[] = useMemo(
    () => [
      {
        name: InterviewerFields.INTERVIEWER,
        label: t('positions.interviewers.interviewer', { count: 1 }),
        type: FormItemType.select,
        options: personsOptions,
        onSearch: getPersonsDebounced,
        rules: [{ required: true, message: t('common.fieldIsRequired') }],
        disabled: isViewing || isEditing,
      },
      {
        name: InterviewerFields.EXPERTISE,
        label: t('positions.interviewers.expertise'),
        type: FormItemType.tagCollection,
        tagCollectionConfig: {
          items: expertise,
          addButtonText: t('positions.interviewers.addExpertise'),
          form,
          onChange: ({ tags }) => {
            setExpertise(tags);
          },
          options: expertiseOptions,
          isEditable: !isViewing,
        },
        rules: [
          {
            required: true,
            message: t('positions.interviewers.addOneExpertise'),
          },
          {
            max: Constants.EXPERTISE_MAX_FIELD_LENGTH,
            message: t('common.fieldMustNotExceedXCharacters', {
              x: Constants.EXPERTISE_MAX_FIELD_LENGTH,
            }),
          },
        ],
      },
    ],
    [
      expertise,
      expertiseOptions,
      form,
      getPersonsDebounced,
      isEditing,
      isViewing,
      personsOptions,
      t,
    ]
  );

  const save = useCallback(async () => {
    const action = isEditing
      ? positionsService.editInterviewer
      : positionsService.addInterviewer;
    const response = await action({ body });

    if (updateExpertiseOptions) {
      expertise.forEach((exp) => updateExpertiseOptions(exp.name));
    }

    showNotification(t, {
      description: response.message,
      type: 'success',
    });
  }, [body, isEditing, t, expertise, updateExpertiseOptions]);

  const tryToSave = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    try {
      setIsLoading(true);
      await save();
      closeModal();
    } catch (error) {
      getErrorInfo(error as Promise<string>, t);
    } finally {
      setIsLoading(false);
    }
  }, [closeModal, form, save, setIsLoading, t]);

  const tryToCancel = useCallback(async () => {
    if (isViewing) {
      closeModal();
      return;
    }

    const originalBody = isEditing
      ? {
          [InterviewerFields.EXPERTISE]: defaultValues.expertise,
          id: defaultValues.interviewerId,
          personId: undefined,
        }
      : defaultValues;

    const temporalBody = isEditing
      ? body
      : {
          ...body,
          [InterviewerFields.INTERVIEWER]: form.getFieldValue(
            InterviewerFields.INTERVIEWER
          ),
        };

    const hasChanged = lookForChanges(temporalBody, originalBody);

    if (!hasChanged) {
      closeModal();
      return;
    }

    const wasCanceled = await confirmCancellation();

    if (wasCanceled) {
      closeModal();
    }
  }, [
    body,
    closeModal,
    confirmCancellation,
    defaultValues,
    form,
    isEditing,
    isViewing,
  ]);

  const getInterviewer = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await positionsService.getInterviewer({ id: +id });

      setDefaultValues(response.result);
      setExpertise(response.result.expertise);
      form.setFieldValue(
        InterviewerFields.INTERVIEWER,
        response.result.fullName
      );
    } catch (error) {
      getErrorInfo(error as Promise<string>, t);
    } finally {
      setIsLoading(false);
    }
  }, [form, id, t]);

  useEffect(() => {
    if (id) {
      getInterviewer();
    }
  }, [getInterviewer, id]);

  return (
    <Modal
      open={isOpen}
      title={title}
      viewMode={isViewing}
      okText={t('common.save')}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{
        disabled: isLoading,
        style: { marginLeft: !isViewing ? '8px' : 'auto' },
      }}
      onOk={tryToSave}
      onCancel={tryToCancel}
    >
      <Form form={form} layout="vertical">
        {fields.map((field) => (
          <FormInput formItemConfig={field} />
        ))}
      </Form>
      <CancellationModal />
    </Modal>
  );
}

export default InterviewerModal;

import { Endpoints } from 'shared/constants/endpoints.const';
import { generatePath } from 'react-router';
import { apiService } from './api.service';

class CompanyService {
  getCompaniesByUser = (userId: string): Promise<DTO.UserCompanies> => {
    return apiService.request({
      url: `${Endpoints.getCompaniesByUser}/${userId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getCompanies = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.Companies> => {
    return apiService.request({
      url: Endpoints.getCompanies,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  addCompany = (company: DTO.Company): Promise<DTO.Company> => {
    return apiService.request({
      url: Endpoints.AddCompany,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  updateCompanyFromAdministration = (
    company: DTO.Company
  ): Promise<DTO.Company> => {
    return apiService.request({
      url: Endpoints.UpdateCompanyFromAdministration,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  updateCompanyFromConfiguration = (
    company: DTO.CompanyFromConfiguration
  ): Promise<DTO.CompanyFromConfiguration> => {
    return apiService.request({
      url: Endpoints.UpdateCompanyFromConfiguration,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  addCompanySettings = (company: DTO.Company): Promise<any> => {
    return apiService.request({
      url: Endpoints.UpdateCompanySettings,
      options: {
        method: 'POST',
        body: JSON.stringify(company),
      },
    });
  };

  updateCompanySettings = (company: DTO.Company): Promise<any> => {
    return apiService.request({
      url: Endpoints.UpdateCompanySettings,
      options: {
        method: 'PUT',
        body: JSON.stringify(company),
      },
    });
  };

  getCompanySettings = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getCompanySettings}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  addCompanySetting = (addSetting: {
    key: string;
    value: string;
    companyId: number;
  }): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.addCompanySetting}`,
      options: {
        method: 'POST',
        body: JSON.stringify(addSetting),
      },
    });
  };

  editCompanySetting = (updateSetting: {
    id: number;
    key: string;
    value: string;
    companyId: number;
    isActive: boolean;
    isDeleted: boolean;
  }): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateCompanySetting}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(updateSetting),
      },
    });
  };

  updateCompanyIsActive = (
    companyId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.updateCompanyIsActive}${companyId}?isActive=${isActive}`,
      options: {
        method: 'PUT',
      },
    });
  };

  getCompanySetting = (
    settingName: string
  ): Promise<DTO.ActionResult<DTO.CompanySetting>> => {
    return apiService.request({
      url: `${Endpoints.getCompanySetting}/${settingName}`,
      options: {
        method: 'GET',
      },
    });
  };

  getMaxDailyWorkingHours = (): Promise<DTO.ActionResult<string>> => {
    return apiService.request({
      url: Endpoints.getMaxDailyWorkingHours,
      options: {
        method: 'GET',
      },
    });
  };

  deleteCompany = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteCompany}/${companyId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getCompanyById = (companyId: string): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getCompanyById}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getAllCompanySettings = (
    companyId: number,
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: any
  ): Promise<DTO.GridResults<any>> => {
    const finalFilter = filter || {};
    finalFilter.companyId = companyId.toString();
    return apiService.request({
      url: Endpoints.getAllCompanySettings,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: finalFilter,
        }),
      },
    });
  };

  deleteCompanySetting = (
    companySettingId: number,
    companyId: number
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteCompanySetting}?companySettingId=${companySettingId}&companyId=${companyId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  updateCompanySettingIsActive = (
    companySettingId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: isActive
        ? Endpoints.activeCompanySetting
        : Endpoints.inactiveCompanySetting,
      options: {
        method: 'PATCH',
        body: companySettingId?.toString(),
      },
    });
  };

  setCompanySecurityKey = (companyId: number, value: string): Promise<void> => {
    return apiService.request({
      url: `${Endpoints.companySecurityKey}`,
      options: {
        method: 'POST',
        body: JSON.stringify({ companyId, value }),
      },
    });
  };

  getCompanySecurityKey = (
    companyId: number
  ): Promise<DTO.ActionResult<boolean>> => {
    return apiService.request({
      url: `${Endpoints.companySecurityKey}/${companyId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getCompanyPermissions = (props: {
    companyId: number;
  }): Promise<DTO.ActionResult<DTO.Permissions[]>> => {
    return apiService.request({
      url: generatePath(Endpoints.getCompanyPermissions, props),
      options: {
        method: 'GET',
      },
    });
  };

  updateCompanyPermissions = (props: {
    companyId: number;
    permissions: string[];
  }): Promise<DTO.ActionResult<void>> => {
    return apiService.request({
      url: Endpoints.updateCompanyPermissions,
      options: {
        method: 'PUT',
        body: JSON.stringify(props),
      },
    });
  };

  getCatalogues = (props: {
    body: DTO.GetPaginatedList;
  }): Promise<DTO.GridResults<DTO.CompanyCatalogue>> => {
    const { body } = props;

    return apiService.request({
      url: Endpoints.getCatalogues,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  getContracts = (props: {
    body: DTO.GetPaginatedList<DTO.ContractType>;
  }): Promise<DTO.GridResults<DTO.ContractType>> => {
    return apiService.request({
      url: Endpoints.getContracts,
      options: {
        method: 'POST',
        body: JSON.stringify(props.body),
      },
    });
  };

  addContract = (props: {
    name: string;
    description: string;
    companyId: number;
  }): Promise<DTO.ActionResult<DTO.ContractType>> => {
    return apiService.request({
      url: Endpoints.addContract,
      options: {
        method: 'POST',
        body: JSON.stringify(props),
      },
    });
  };

  updateContract = (props: {}): Promise<DTO.ActionResult<void>> => {
    return apiService.request({
      url: Endpoints.updateContract,
      options: {
        method: 'PUT',
        body: JSON.stringify(props),
      },
    });
  };

  deleteContractType = (props: {
    contractTypeId: number;
  }): Promise<DTO.GridResults<void>> => {
    const { contractTypeId } = props;
    return apiService.request({
      url: generatePath(Endpoints.deleteContract, {
        contractTypeId,
      }),
      options: {
        method: 'DELETE',
      },
    });
  };

  getContractTypeById = (
    contractTypeId: number
  ): Promise<DTO.ActionResult<DTO.ContractType>> => {
    return apiService.request({
      url: generatePath(Endpoints.getContractTypeById, {
        contractTypeId,
      }),
      options: {
        method: 'GET',
      },
    });
  };

  getContractHoursTypes = (
    props: DTO.GetPaginatedList<DTO.ContractType>,
    contractId: number
  ): Promise<DTO.GridResults<DTO.ContractHours>> => {
    return apiService.request({
      url: `${Endpoints.getContractHoursTypes}?contractTypeId=${contractId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(props),
      },
    });
  };

  getContractHourTypeById = (
    contractHourTypeId: number
  ): Promise<DTO.ActionResult<DTO.ContractHours>> => {
    return apiService.request({
      url: `${Endpoints.getContractHourTypeById}${contractHourTypeId}`,
      options: {
        method: 'GET',
      },
    });
  };

  updateContractHoursTypes = (
    props: Partial<DTO.ContractHours>
  ): Promise<DTO.GridResults<DTO.ContractHours[]>> => {
    return apiService.request({
      url: Endpoints.updateContractHoursTypes,
      options: {
        method: 'POST',
        body: JSON.stringify(props),
      },
    });
  };

  createContractHoursTypes = (
    props: Partial<DTO.ContractHours>
  ): Promise<DTO.GridResults<DTO.ContractHours[]>> => {
    return apiService.request({
      url: Endpoints.createContractHoursTypes,
      options: {
        method: 'POST',
        body: JSON.stringify(props),
      },
    });
  };

  getHoursTypes = (): Promise<DTO.ActionResult<DTO.HoursTypes[]>> => {
    return apiService.request({
      url: Endpoints.getHoursTypes,
      options: {
        method: 'GET',
      },
    });
  };

  addAdminUser = (user: DTO.AdminUser): Promise<DTO.ActionResult<void>> => {
    const { companyId, ...body } = user;

    return apiService.request({
      url: `${Endpoints.addAdminUser}/${companyId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };
}

export const companyService = new CompanyService();

import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Popover from 'components/Popover';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormModes } from 'shared/constants/common.const';
import { Badge } from 'antd';
import { ButtonPill, ButtonPillStars, CloseButton, RateStyled } from './styles';
import DeleteSVGIcon from './deleteIcon';

interface Props {
  data: DTO.PositionSkills;
  childIdDeleted: Function;
  formMode: FormModes;
  rate: number;
  count: number;
  onChange?: (props: { data: DTO.PositionSkills; value: number }) => void;
}

const EditPopover: FC<Props> = ({
  data,
  childIdDeleted,
  formMode,
  rate,
  count,
  onChange,
}: Props): JSX.Element => {
  const [idDeleted, setIdDeleted] = useState<number | string>();
  const [reactiveRate, setReactiveRate] = useState<number>(rate);

  useEffect(() => {
    if (idDeleted) childIdDeleted(idDeleted);
  }, [idDeleted, childIdDeleted]);

  const DeleteIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={DeleteSVGIcon} {...props} />
  );

  const onStarsChange = useCallback(
    (props: { data: DTO.PositionSkills; value: number }) => {
      const { value } = props;
      setReactiveRate(value);
      onChange?.(props);
    },
    [onChange]
  );

  return (
    <Popover key={data.id} placement="bottomLeft" trigger="click">
      {(formMode === FormModes.created ||
        formMode === FormModes.creating ||
        formMode === FormModes.edit) && (
        <Badge
          offset={[-18, 12]}
          count={
            <CloseButton
              type="link"
              onClick={() => {
                setIdDeleted(data.id);
              }}
              icon={<DeleteIcon />}
            />
          }
        >
          <ButtonPillStars key={data.id}>
            {data.name}
            <RateStyled
              count={count}
              value={reactiveRate}
              onChange={(value: number) =>
                onStarsChange({
                  value,
                  data: { ...data, skillLevelId: value },
                })
              }
            />
          </ButtonPillStars>
        </Badge>
      )}
      {formMode === FormModes.viewing && (
        <ButtonPill key={data.id}>{data.name}</ButtonPill>
      )}
    </Popover>
  );
};

EditPopover.defaultProps = {
  onChange: undefined,
};

export default memo(EditPopover);

import React from 'react';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import flowhrLogo from 'shared/images/flowhr_logo.svg';
import { LayoutConfig } from 'features/Layouts/helpers';
import { UsergroupAddOutlined } from '@ant-design/icons';

import { positionsService } from 'shared/services/positions.service';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import { COMPANY_ID } from 'shared/constants/common.const';
import moment from 'moment';
import {
  FilterOptions,
  DATE_FORMAT,
} from '../../Modules/OpenPositions/Positions/PositionsTable/helpers';

const POSITIONS_CONFIG: LayoutConfig = {
  title: 'positions.title',
  logo: flowhrLogo,
  menuItems: [
    {
      key: 1,
      name: 'positions.positionsList',
      icon: <UsergroupAddOutlined />,
      path: PLACEMENT_PATH.POSITIONS,
      nestedPaths: [
        {
          name: 'positions.createPosition',
          path: PLACEMENT_PATH.ADD_POSITION,
        },
        {
          name: '',
          path: PLACEMENT_PATH.VIEW,
        },

        {
          name: 'positions.editPosition',
          path: PLACEMENT_PATH.EDIT,
        },
      ],
    },
    {
      key: 2,
      name: 'positions.candidate.candidate',
      icon: <UsergroupAddOutlined />,
      path: PLACEMENT_PATH.CANDIDATES,
    },
  ],
};

const fetchPositionData: Function = async (
  filter: FilterOptions,
  page: number,
  size: number
): Promise<DTO.PositionPaginationTable> => {
  const promises = [
    positionsService.getPositionsByCompany(page, size, filter),
    positionsService.getPositionSeniority(),
    positionsService.getPositionPlacementProject(),
    positionsService.getPlacementPositionRoles(),
  ];
  const countriesAvailable = await positionsService.getPositionCountry();
  const promisesResult = await Promise.all(
    promises.map((pm) => pm.then((data) => data).catch(() => []))
  );
  const positionsResponse =
    promisesResult[0] as DTO.PositionPagination<DTO.PositionsByCompany>;
  const senioritiesResponse =
    promisesResult[1] as DTO.PositionResponse<DTO.PositionSeniority>;
  const projectsResponse =
    promisesResult[2] as DTO.PositionResponse<DTO.PositionPlacementProject>;
  const rolesResponse =
    promisesResult[3] as DTO.PositionResponse<DTO.PositionRoles>;

  const positions: DTO.PositionTable[] = positionsResponse.results.map(
    (position) => {
      const countryArray = position.positionAllocations.map((value: any) => {
        return value.countryAllocationId;
      });
      const countryList = countryArray.map((country: string) => {
        const filterCountries: any = countriesAvailable.find(
          (c: any) => c.countryId === country
        );
        return filterCountries.name;
      });
      return {
        key: position.id,
        id: position.id,
        positionName: position.positionName,
        roleName: rolesResponse.results.find(
          (role) => role.id === position.roleId
        )?.name,
        seniorityName: senioritiesResponse.results.find(
          (senior) => senior.id === position.seniorityId
        )?.name,
        clientName: position.clientName,
        projectName: projectsResponse.results.find(
          (project) => project.id === position.projectId
        )?.name,
        allocation: countryList,
        startDate: moment(position.positionStartDate).format(DATE_FORMAT),
        status: position.isActive,
      };
    }
  );
  return {
    page: positionsResponse.page,
    pageSize: positionsResponse.pageSize,
    data: positions,
    total: positionsResponse.total,
  };
};

const fetchPositionStatus: Function = async () => {
  const currentCompanyId = getFromSessionStorage(COMPANY_ID);

  const data: any =
    await positionsService.getPlacementPositionStatusByCompanyId(
      currentCompanyId
    );

  return data.results.map((st: any) => {
    return { key: st.id, reason: st.reason };
  });
};

export { POSITIONS_CONFIG, fetchPositionData, fetchPositionStatus };

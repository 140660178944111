import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled(Select)<{
  width: string;
  height?: string;
  margin?: string;
  status?: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}

  div.ant-select-selector {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius} !important;
    height: ${({ height }) => height} !important;

    ${({ status, theme }) => {
      if (status === 'error') {
        return `border-color: ${theme.colors.red}`;
      }
      return ``;
    }}
  }

  span.ant-select-selection-item,
  span.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

export const OptionStyled = styled(Select.Option)<{
  width?: string;
  height?: string;
  margin?: string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ActionsAntTable from 'components/ActionsAntTable';
import { ActionsConfig, RefObject } from 'components/ActionsAntTable/helpers';
import { useForm } from 'components/Form';
import Pagination from 'components/Pagination';
import { PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { Link } from 'react-router-dom';
import { FormModes } from 'shared/constants/common.const';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { hasPermission } from 'shared/utils/common.utils';
import { IconWrapper } from 'components/AntTable/styles';
import { TooltipStyled } from 'components/Tooltip/styles';
import { Options } from 'components/Form/helpers';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { InputStyled } from 'components/Input/styles';
import { Button } from 'components/Button';
import { Space, Style } from './style';
import {
  CandidatesColumn,
  CandidatesColumnTitle,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  getPositionsHelper,
  getStatusList,
} from './helper';
import {
  DivFilter,
  EditOutlinedDisable,
  SearchOutlinedStyled,
} from '../Positions/PositionsTable/style';
import { FILTER_ICON_ACTIVE } from '../Positions/PositionsTable';

const Candidates = () => {
  const history = useHistory();
  const formRef = useRef<RefObject>(null);
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [statusList, setStatusList] = useState<Options[]>([]);
  const [paginationProps, setPaginationProps] = useState<PaginationProps>({
    current: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [totalResults, setTotalResults] = useState(0);
  const [filterObj, setFilterObj] = useState<
    Partial<Record<CandidatesColumn, any>>
  >({});
  const [candidatesResult, setCandidatesResult] = useState<
    DTO.CandidatesListObject[]
  >([]);
  const { t } = useTranslation();

  const params: DTO.GetCandidatesRequest = useMemo(() => {
    return {
      valueId: '',
      page: paginationProps.current || DEFAULT_PAGE,
      pageSize: paginationProps.pageSize || DEFAULT_PAGE_SIZE,
      searchTerm: null,
      sortColumn: null,
      sortDirection: 'asc',
      filter: filterObj,
    };
  }, [filterObj, paginationProps]);

  const handleReset = useCallback(
    (clearFilters: () => void, dataIndex: CandidatesColumn) => {
      clearFilters();
      setFilterObj((prev) => {
        const filter = { ...prev };
        const index =
          dataIndex === CandidatesColumn.status
            ? CandidatesColumn.statusName
            : dataIndex;
        delete filter[index as keyof typeof filter];
        return filter;
      });
    },
    []
  );

  const handleSearch = useCallback(
    (
      selectedKeys: string,
      confirm: (param?: any) => void,
      dataIndex: CandidatesColumn
    ) => {
      setFilterObj((prev) => {
        const filter: typeof filterObj = { ...prev };
        const index =
          dataIndex === CandidatesColumn.status
            ? CandidatesColumn.statusName
            : dataIndex;
        filter[index] = selectedKeys;
        return filter;
      });
      confirm();
      setPaginationProps((prev) => ({ ...prev, current: 1 }));
    },
    []
  );

  const getColumnSearchProps = useCallback(
    (dataIndex: CandidatesColumn) => {
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
          return (
            <DivFilter>
              <InputStyled
                placeholder={`${t('common.search')} ${t(
                  CandidatesColumnTitle[
                    dataIndex as keyof typeof CandidatesColumnTitle
                  ]
                ).toLocaleLowerCase()}`}
                value={selectedKey}
                onChange={(e) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }}
                onPressEnter={() => {
                  handleSearch(selectedKey, confirm, dataIndex);
                  confirm();
                }}
              />
              <Space />
              <Button
                onClick={() => {
                  handleSearch(selectedKey, confirm, dataIndex);
                  confirm({ closeDropdown: true });
                }}
                icon={<SearchOutlinedStyled />}
                type="primary"
              >
                {t('common.search')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  if (clearFilters) handleReset(clearFilters, dataIndex);
                  confirm({ closeDropdown: true });
                }}
              >
                {t('common.reset')}
              </Button>
            </DivFilter>
          );
        },
        filterIcon: (filtered: boolean) => (
          <SearchOutlinedStyled filtered={filtered ? FILTER_ICON_ACTIVE : ''} />
        ),
        onFilter: (value: string | number | boolean, record: DTO.Candidate) => {
          if (typeof value === 'string') {
            const result = record[dataIndex as keyof DTO.Candidate];
            return !!result;
          }
          return false;
        },
        render: (text: string) => {
          return t(text);
        },
      };
    },
    [handleReset, handleSearch, t]
  );

  const columns = useMemo(() => {
    function getStatusName(statusId: string) {
      return statusList.find((status) => status.value === statusId)?.name || '';
    }

    return [
      {
        key: CandidatesColumn.name,
        title: t(CandidatesColumnTitle.name),
        dataIndex: CandidatesColumn.name,
        ...getColumnSearchProps(CandidatesColumn.name),
      },
      {
        key: CandidatesColumn.email,
        title: t(CandidatesColumnTitle.email),
        dataIndex: CandidatesColumn.email,
        ...getColumnSearchProps(CandidatesColumn.email),
      },
      {
        key: CandidatesColumn.country,
        title: t(CandidatesColumnTitle.country),
        dataIndex: CandidatesColumn.country,
        ...getColumnSearchProps(CandidatesColumn.country),
        render: (country: string) => t(country),
      },
      {
        key: CandidatesColumn.role,
        title: t(CandidatesColumnTitle.role),
        dataIndex: CandidatesColumn.role,
        ...getColumnSearchProps(CandidatesColumn.role),
      },
      {
        key: CandidatesColumn.status,
        title: t(CandidatesColumnTitle.status),
        dataIndex: CandidatesColumn.status,
        ...getColumnSearchProps(CandidatesColumn.status),
        render: getStatusName,
      },
      {
        key: CandidatesColumn.actions,
        title: t(CandidatesColumnTitle.actions),
        dataIndex: CandidatesColumn.actions,
        fixed: 'right' as const,
        render: (_: void, candidate: DTO.CandidatesListObject) => {
          const pushToView = (routeId: DTO.CandidatesListObject['id']) => {
            history.push(
              `${generatePath(PLACEMENT_PATH.CANDIDATE_FORM, {
                mode: FormModes.viewing,
              })}?candidateId=${routeId}`
            );
          };
          const pushToEdit = (routeId: DTO.CandidatesListObject['id']) => {
            history.push(
              `${generatePath(PLACEMENT_PATH.CANDIDATE_FORM, {
                mode: FormModes.editing,
              })}?candidateId=${routeId}`
            );
          };
          return (
            <>
              {hasPermission(
                'placement.candidate.candidate_view',
                PLACEMENT_PATH.CANDIDATES
              ) && (
                <IconWrapper>
                  <TooltipStyled
                    title={t('positions.actionTitles.viewCandidate')}
                  >
                    <EyeOutlined onClick={() => pushToView(candidate.id)} />
                  </TooltipStyled>
                </IconWrapper>
              )}

              {candidate.isActive &&
                hasPermission(
                  'placement.candidate.candidate_edit',
                  PLACEMENT_PATH.CANDIDATES
                ) && (
                  <IconWrapper>
                    <TooltipStyled
                      title={t('positions.actionTitles.editCandidate')}
                    >
                      <EditOutlined onClick={() => pushToEdit(candidate.id)} />
                    </TooltipStyled>
                  </IconWrapper>
                )}

              {!candidate.isActive &&
                hasPermission(
                  'placement.openPositions.openPositions_edit',
                  PLACEMENT_PATH.POSITIONS
                ) && (
                  <IconWrapper>
                    <TooltipStyled
                      title={t('positions.actionTitles.editPosition')}
                    >
                      <EditOutlinedDisable />
                    </TooltipStyled>
                  </IconWrapper>
                )}
            </>
          );
        },
      },
    ];
  }, [getColumnSearchProps, history, statusList, t]);

  const onPageChange = (page: number, pageSize?: number) => {
    setPaginationProps({
      ...paginationProps,
      current: page,
      pageSize: pageSize || paginationProps.pageSize,
    });
  };

  const getList = useCallback(async () => {
    setIsLoading(true);
    const response = await getPositionsHelper(params);
    setCandidatesResult(response.results);
    setTotalResults(response.total);
    setIsLoading(false);
  }, [params]);

  const initialize = useCallback(async () => {
    await getList();
    setStatusList(await getStatusList(t));
  }, [getList, t]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Style.Container>
      <Style.Header>
        <Style.SectionTitle>
          {t('positions.candidate.candidate')}
        </Style.SectionTitle>
        {hasPermission('placement.candidate.candidate_add') && (
          <Link
            to={generatePath(PLACEMENT_PATH.CANDIDATE_FORM, {
              mode: FormModes.creating,
            })}
          >
            <Style.Button type="primary">{t('common.add')}</Style.Button>
          </Link>
        )}
      </Style.Header>
      {hasPermission('placement.candidate.candidate_viewAll') && (
        <Style.TableWrapper>
          <ActionsAntTable
            ref={formRef}
            columns={columns}
            data={candidatesResult}
            actionsConfig={[] as ActionsConfig}
            form={form}
            isLoading={isLoading}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            {...paginationProps}
            onChange={onPageChange}
            onShowSizeChange={onPageChange}
            showSizeChanger
            total={totalResults}
            showTotal={(total, range) =>
              total !== -1
                ? `${range[0]}-${range[1]} ${t(
                    'timetracker.timesheet.ofTotalHours'
                  )} ${total} ${t('timetracker.menu.people')}`
                : ''
            }
          />
        </Style.TableWrapper>
      )}
    </Style.Container>
  );
};

export default Candidates;

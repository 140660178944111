import React, { useCallback, useContext, useMemo } from 'react';
import Select from 'components/Select';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { generatePath, useHistory, useParams } from 'react-router';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FilterContainer, RefreshButtonStyled } from './styles';
import { InterviewerContext } from '../../InterviewerContext';

export const EXPERTISE = 'expertise';

function ExpertiseFilter() {
  const { t } = useTranslation();
  const { expertiseOptions, expertiseFilter, setExpertiseFilter } =
    useContext(InterviewerContext);
  const params = useParams();
  const history = useHistory();
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );

  const defaultValues = useMemo(
    () => searchParams.get(EXPERTISE)?.split(',') as unknown as string,
    [searchParams]
  );

  const filterInterviewers = useCallback(() => {
    if (expertiseFilter.length === 0) {
      searchParams.delete(EXPERTISE);
    } else {
      searchParams.set(EXPERTISE, expertiseFilter.join(','));
    }

    history.push({
      pathname: generatePath(PLACEMENT_PATH.INTERVIEWERS, params),
      search: searchParams.toString(),
    });
  }, [expertiseFilter, history, params, searchParams]);

  return (
    <FilterContainer>
      <Select
        width="275px"
        mode="multiple"
        options={expertiseOptions}
        placeholder={t('positions.interviewers.selectExpertise')}
        onChange={(values) => setExpertiseFilter(values as unknown as string[])}
        defaultValue={defaultValues}
      />
      <RefreshButtonStyled
        type="primary"
        shape="round"
        icon={<SearchOutlined />}
        onClick={filterInterviewers}
      />
    </FilterContainer>
  );
}

export default ExpertiseFilter;

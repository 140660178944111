import React, { FC, memo, PropsWithChildren } from 'react';
import { ModalProps as AntdModalProps } from 'antd';
import { ModalStyled } from './styles';
import { ModalButtonsAlignment } from './types';

const Modal: FC<
  PropsWithChildren<
    AntdModalProps & {
      footerButtonsAlignment?: ModalButtonsAlignment;
      viewMode?: boolean;
    }
  >
> = ({ children, viewMode, ...restProps }): JSX.Element => {
  return (
    <ModalStyled
      {...restProps}
      okButtonProps={{ style: { display: viewMode ? 'none' : 'block' } }}
      cancelButtonProps={{
        style: { marginLeft: viewMode ? 'auto' : '10px' },
      }}
    >
      {' '}
      {children}{' '}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  footerButtonsAlignment: ModalButtonsAlignment.SPACE_BETWEEN,
  viewMode: false,
};

export default memo(Modal);

import React, { FC, Suspense, useCallback, lazy, useEffect } from 'react';
import { RouteProps, Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { USER_PATHS, ProfileOptions } from 'shared/constants/common.const';
import { showNotification } from 'shared/utils/common.utils';
import { getFromSessionStorage } from 'shared/utils/storage.utils';
import BaseLayout from 'features/Layouts';
import { LayoutConfig } from 'features/Layouts/helpers';
import { ADMINISTRATION_PATH, APP_PATH } from '../helpers';
import { Permissions } from '../GuardedRoute/helpers';
import { getAllLayout } from './helpers';

const GuardedRoute = lazy(() => import('features/Routes/GuardedRoute'));
const CompaniesContainer = lazy(
  () => import('features/Modules/Administration/Companies/companies.container')
);
const CompanyGroupsPage = lazy(
  () => import('features/Modules/Administration/CompanyGroups')
);

const AdministrationRoutes: FC<RouteProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userPaths: Permissions[] = getFromSessionStorage(USER_PATHS);

  const layout: LayoutConfig = getAllLayout(userPaths);

  useEffect(() => {
    if (layout.menuItems.length === 0) {
      showNotification(t, {
        type: 'error',
        description: 'common.notificationUserNotAllow',
      });
      history.push(APP_PATH.HOME);
    }
  }, [layout, history, t]);

  const CompanyGroupPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={ADMINISTRATION_PATH.COMPANY_GROUP}
        component={() => <CompanyGroupsPage />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  const CompaniesContainerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={ADMINISTRATION_PATH.COMPANIES}
        component={() => <CompaniesContainer />}
        option={ProfileOptions.option}
      />
    ),
    []
  );

  return (
    <BaseLayout config={layout}>
      <Suspense fallback={<Spin />}>
        <Switch>
          <Route
            path={ADMINISTRATION_PATH.COMPANIES}
            render={CompaniesContainerPath}
          />
          <Route
            path={ADMINISTRATION_PATH.COMPANY_GROUP}
            render={CompanyGroupPath}
          />
        </Switch>
      </Suspense>
    </BaseLayout>
  );
};

export default AdministrationRoutes;

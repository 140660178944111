import React, { FC, memo, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { positionsService } from 'shared/services/positions.service';
import { useHistory, useParams } from 'react-router';
import { hasPermission, showNotification } from 'shared/utils/common.utils';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { useTranslation } from 'react-i18next';
import PositionView from './PositionView';

type Param = {
  positionId: string;
};

const PositionItem: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  if (
    !hasPermission(
      'placement.openPositions.openPositions_view',
      PLACEMENT_PATH.POSITIONS
    )
  ) {
    showNotification(t, {
      type: 'error',
      description: 'positions.youCanNotSeeThePosition',
    });
    history.replace(PLACEMENT_PATH.POSITIONS);
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { positionId } = useParams<Param>();
  const [position, setPosition] = useState<DTO.Position | undefined>();
  const getPositionsById = positionsService.getPositionById;

  useEffect(() => {
    getPositionsById(positionId).then((data: any) => {
      setPosition(data.result);
      setIsLoading(false);
    });
  }, [getPositionsById, positionId]);

  return (
    <>
      {isLoading || position === undefined ? (
        <LoadingOutlined />
      ) : (
        <PositionView position={position} />
      )}
    </>
  );
};

export default memo(PositionItem);

import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class SalesService {
  getSalesChannels = (): Promise<DTO.ActionResult<DTO.Catalogue[number]>> => {
    return apiService.request({
      url: Endpoints.getSalesChannels,
      options: {
        method: 'GET',
      },
    });
  };
}

export const salesService = new SalesService();

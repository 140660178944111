import React from 'react';
import { showNotification, showNotificationList } from './common.utils';

export const showError = (error: AppError.CatchError, t: Function): void => {
  const { errors } = error;
  const errorMessages: string[] = [];

  Object.keys(errors).forEach((key) => {
    errors[key].forEach((errorInfo: string) => {
      errorMessages.push(t(errorInfo));
    });
  });

  const errorDetails = (
    <ul>
      {errorMessages.map((errorMessage) => (
        <li key={errorMessage}>{errorMessage}</li>
      ))}
    </ul>
  );

  showNotificationList(t, {
    type: 'error',
    description: errorDetails,
    style: { width: 500 },
    message: t('common.notificationTitles.errors'),
  });
};

export const showNotAuthorizedError = (error: string, t: Function): void => {
  const errorDetails = (
    <ul>
      <li key={error}>{t(error)}</li>
    </ul>
  );

  showNotificationList(t, {
    type: 'error',
    description: errorDetails,
    style: { width: 500 },
    message: t('common.notificationTitles.errors'),
  });
};

export const getErrorInfo = (errorPromise: Promise<any>, t: Function): void => {
  try {
    errorPromise
      .then((errorJson) => JSON.parse(errorJson))
      .then((error: AppError.CatchError) => {
        if (error.status === 400) {
          showError(error, t);
        } else if (error.status === 403) {
          showNotAuthorizedError('timetracker.unauthorizedUser', t);
        }
      })
      .catch(() => {
        showNotification(t, null, true);
      });
  } catch (error) {
    showNotification(t, null, true);
  }
};

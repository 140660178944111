import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class TimeEntryService {
  GetAllByStatusProjectAndDates = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    projectId: DTO.Nullable<string>,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.TimeEntriesInfo> => {
    return apiService.request({
      url: `${Endpoints.getAllAsLeader}${startDate}/${endDate}${
        statusId ? `/${statusId}` : ''
      }`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: `${projectId ? `${projectId}` : ''}`,
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  GetAllFilterByStatusProjectAndDatesReviewer = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    projectId: DTO.Nullable<string>,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.TimeEntriesInfo> => {
    return apiService.request({
      url: `${Endpoints.getAllAsReviewer}${startDate}/${endDate}${
        statusId ? `/${statusId}` : ''
      }`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: `${projectId ? `${projectId}` : ''}`,
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  GetAllAsAdminCompany = (
    page: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    projectId: DTO.Nullable<string>,
    statusId: DTO.Nullable<number>,
    filter: Object
  ): Promise<DTO.TimeEntriesInfo> => {
    return apiService.request({
      url: `${Endpoints.getAllAsAdminCompany}${startDate}/${endDate}${
        statusId ? `/${statusId}` : ''
      }`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: `${projectId ? `${projectId}` : ''}`,
          page,
          pageSize,
          sortDirection: 'desc',
          filter,
        }),
      },
    });
  };

  aceptLeader = (id: number): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: `${Endpoints.aceptTimeEntryLeader}${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  amendLeader = (id: number): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: `${Endpoints.amendTimeEntryLeader}${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  aceptReviewer = (id: number): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: `${Endpoints.aceptTimeEntry}${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  amendReviewer = (id: number): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: `${Endpoints.amendTimeEntry}${id}`,
      options: {
        method: 'PUT',
      },
    });
  };

  voidedReviewer = ({
    timeEntryId,
    comments,
  }: {
    timeEntryId: number;
    comments: string;
  }): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: Endpoints.voidedTimeEntry,
      options: {
        method: 'PUT',
        body: JSON.stringify({
          timeEntryId,
          comments,
        }),
      },
    });
  };

  updateComments = (
    timeEntryId: number,
    comments: string
  ): Promise<DTO.TimeEntries> => {
    return apiService.request({
      url: Endpoints.updateTimeEntryComments,
      options: {
        method: 'POST',
        body: JSON.stringify({
          timeEntryId,
          comments,
        }),
      },
    });
  };

  getById = (timeEntryId: number): Promise<DTO.TimeEntrySingleRow> => {
    return apiService.request({
      url: `${Endpoints.getTimeEntryByIdLeader}${timeEntryId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getByIdReviewer = (timeEntryId: number): Promise<DTO.TimeEntrySingleRow> => {
    return apiService.request({
      url: `${Endpoints.getTimeEntryById}${timeEntryId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const timeEntryService = new TimeEntryService();

import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const ShadowWrapper = styled.div`
  -webkit-box-shadow: 0px 0px 18px -5px rgba(86, 14, 14, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
`;

export const TableWrapper = styled(
  (
    props: PropsWithChildren<{
      noPadding?: boolean;
      hideShadow?: boolean;
      id?: string;
    }>
  ) =>
    props.hideShadow ? (
      <div {...props}>{props.children}</div>
    ) : (
      <ShadowWrapper {...props}>{props.children}</ShadowWrapper>
    )
)`
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  background: #ffffff;
  padding: ${({ noPadding }) => (noPadding ? '0' : '24px')};
`;

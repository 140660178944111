import { Endpoints } from 'shared/constants/endpoints.const';
import { generatePath } from 'react-router';
import { apiService } from '../api.service';

class TypeOfHourService {
  getAll = (props: {
    body: DTO.GetPaginatedList<DTO.HoursTypes>;
  }): Promise<DTO.GridResults<DTO.HoursTypes>> => {
    const { body } = props;
    return apiService.request({
      url: Endpoints.getAllTypesOfHours,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  add = (typeOfHour: DTO.AddHoursTypes): Promise<DTO.ActionResult<void>> => {
    return apiService.request({
      url: Endpoints.addTypeOfHours,
      options: {
        method: 'POST',
        body: JSON.stringify(typeOfHour),
      },
    });
  };

  edit = (typeOfHour: DTO.HoursTypes): Promise<DTO.ActionResult<void>> => {
    return apiService.request({
      url: Endpoints.editTypeOfHour,
      options: {
        method: 'PUT',
        body: JSON.stringify(typeOfHour),
      },
    });
  };

  delete = (props: {
    record: DTO.HoursTypes;
  }): Promise<DTO.ActionResult<void>> => {
    const { id } = props.record;
    return apiService.request({
      url: generatePath(Endpoints.deleteHourType, {
        id,
      }),
      options: {
        method: 'DELETE',
      },
    });
  };

  get = (props: { id: number }): Promise<DTO.ActionResult<DTO.HoursTypes>> => {
    return apiService.request({
      url: generatePath(Endpoints.getHourTypeById, props),
      options: {
        method: 'GET',
      },
    });
  };
}

export const typeOfHourService = new TypeOfHourService();

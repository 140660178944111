export const enum InterviewerTableColumns {
  INTERVIEWER_ID = 'interviewerId',
  INTERVIEWER = 'fullName',
  EMAIL = 'emailAddress',
  EXPERTISE = 'expertise',
  STATUS = 'status',
  ACTIONS = 'Actions',
}

export interface InterviewerColumnsTypes
  extends Record<InterviewerTableColumns, any> {
  [InterviewerTableColumns.INTERVIEWER]: string;
  [InterviewerTableColumns.EMAIL]: string;
  [InterviewerTableColumns.EXPERTISE]: string;
  [InterviewerTableColumns.STATUS]: boolean;
  [InterviewerTableColumns.ACTIONS]: string;
  [InterviewerTableColumns.INTERVIEWER_ID]: number;
}

export const MAX_SKILLS = 3;

export const TABLE_NAME = 'interviewers';

import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from 'components/Input';
import styled from 'styled-components';

export const EditOutlinedDisable = styled(EditOutlined)`
  color: ${({ theme }) => theme.colors.primaryDisabled};
`;

export const SearchOutlinedStyled = styled(SearchOutlined)<{
  filtered?: string;
}>`
  color: ${({ filtered }) =>
    filtered ? ({ theme }) => theme.colors.links : undefined};
`;
export const DivFilter = styled.div`
  padding: 8px;
`;
export const InputStyled = styled(Input)`
  margin-bottom: 8px;
  display: block;
`;
export const DatePickerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const PaginationDiv = styled.div`
  display: flex;
  justify-content: end;
  margin: 16px 0;
`;

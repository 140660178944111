import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Tabs from 'components/Tabs';
import { TabInstance } from 'components/Timetracker/Form/TabForm/PreviousNextTabOptions/helpers';
import { useTranslation } from 'react-i18next';
import { useForm } from 'components/Form';
import { FormModes } from 'shared/constants/common.const';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import { getErrorInfo } from 'shared/utils/error.utils';
import { positionsService } from 'shared/services/positions.service';
import { notification } from 'antd';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Tab } from 'features/Modules/Timetracker/People/Person/helpers';
import { POSITION_TAB, SKILLS_KEY_TAB } from './NewPosition/helper';
import NewPosition from './NewPosition';
import Skills from './NewPosition/Skills';
import AddPositionContext from './addPositionsContext';
import {
  AllSkills,
  AllSkillsEmun,
  INITIAL_SKILLS_DATA,
  SkillTypes,
} from './helpers';
import { TitleStyled } from './styles';

const AddPositions = () => {
  const { t } = useTranslation();
  const params = useParams<{ positionId: string }>();
  const [positionId, setPositionId] = useState<string>(params?.positionId);
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [tabs, setTabs] = useState<TabInstance>({
    defaultTab: 1,
    activeTab: 1,
  });
  const [currentPosition, setCurrentPosition] = useState<
    DTO.Position | undefined
  >();
  const [isTabDisabled, setIsTabDisabled] = useState<boolean>(
    !window.location.pathname.includes('edit')
  );
  const [selectedSkills, setSelectedSkills] = useState<DTO.PositionSkills[]>(
    []
  );
  const [formMode] = useState<FormModes>(
    window.location.pathname.includes('edit')
      ? FormModes.edit
      : FormModes.creating
  );

  const [allSkills, setAllSkills] = useState<AllSkills>(INITIAL_SKILLS_DATA);
  const handleSetAllSkills = useCallback(
    (skillType: AllSkillsEmun) => (skills: SkillTypes[]) => {
      setAllSkills({ ...allSkills, [skillType]: skills });
    },
    [allSkills]
  );
  const [positionForm] = useForm();
  const [items, setItems] = useState<Tab[]>([]);
  const setTab = useCallback((newKey: number) => {
    setTabs((prevState) => {
      const newTabs = { ...prevState };
      newTabs.activeTab = newKey;
      return newTabs;
    });
  }, []);

  const pathToRedirectOnCancel = useMemo(() => PLACEMENT_PATH.POSITIONS, []);

  const pathToRedirect = useMemo(() => {
    if (positionId) {
      return generatePath(PLACEMENT_PATH.VIEW, {
        positionId,
      });
    }

    return PLACEMENT_PATH.POSITIONS;
  }, [positionId]);

  const handleEditRequest = useCallback(
    async (newOpenPosition: DTO.NewPositionDraft) => {
      if (currentPosition) {
        try {
          setSubmitting(true);
          await positionsService.editPositions(
            {
              positionName: newOpenPosition.positionName,
              jobDescription: newOpenPosition.jobDescription,
              mainResponsabilities: newOpenPosition.mainResponsabilities,
              seniorityId: newOpenPosition.seniorityId,
              clientId: currentPosition.clientId,
              clientName: currentPosition.clientName,
              roleName: newOpenPosition.role.name,
              positionTypeId: newOpenPosition.positionTypeId,
              roleId: newOpenPosition.roleId,
              projectId: newOpenPosition.projectId,
              projectName: newOpenPosition.project.name,
              positionStartDate: newOpenPosition.positionStartDate,
              positionAllocations: newOpenPosition.positionAllocations,
              saleId: newOpenPosition.saleId,
              requestBy: newOpenPosition.requestBy,
              openPositionSkills: selectedSkills.map((skill) => ({
                isActive: true,
                skillCategoryId: skill.skillTypeId,
                skillLevelId: skill.skillLevelId,
                skillName: skill.name,
              })),
            },
            currentPosition?.id
          );
          notification.success({
            message: t('common.notificationTitles.success'),
            description: t('common.editSuccessful'),
          });
          history.push(pathToRedirect);
        } catch (e) {
          getErrorInfo(e as Promise<string>, t);
        } finally {
          setSubmitting(false);
        }
      }
    },
    [currentPosition, history, pathToRedirect, selectedSkills, t]
  );

  const handleSaveRequest = useCallback(
    async (newOpenPosition: DTO.NewPositionDraft) => {
      try {
        setSubmitting(true);
        const response = await positionsService.savePositions({
          ...newOpenPosition,
          openPositionSkills: selectedSkills.map((skill) => ({
            isActive: true,
            skillCategoryId: skill.skillTypeId,
            skillLevelId: skill.skillLevelId,
            skillName: skill.name,
          })),
        });
        notification.success({
          message: t('common.notificationTitles.success'),
          description: t('common.saveSuccessful'),
        });
        setPositionId(response.result.id);
      } catch (e) {
        getErrorInfo(e as Promise<string>, t);
      } finally {
        setSubmitting(false);
      }
    },
    [selectedSkills, t]
  );

  const handleRequestAccordingToFormMode = useCallback(
    async (positionData: DTO.NewPositionDraft) => {
      if (tabs.activeTab === 1) {
        setTab(2);
      } else {
        switch (formMode) {
          case FormModes.edit:
            await handleEditRequest(positionData);
            break;
          default:
            await handleSaveRequest(positionData);
            break;
        }
      }
    },
    [handleEditRequest, formMode, handleSaveRequest, setTab, tabs.activeTab]
  );

  const onTabChange = useCallback(
    (key: string) => {
      setTab(Number(key));
    },
    [setTab]
  );

  const onGetPositionById = useCallback(async () => {
    if (params.positionId) {
      const { result } = await positionsService.getPositionById(
        params.positionId
      );
      setCurrentPosition(result);
      setSelectedSkills(
        result.openPositionSkills.map((skillAux) => ({
          companyId: 1,
          createdAt: skillAux.createdAt,
          id: skillAux.id,
          isActive: skillAux.isActive,
          isDeleted: false,
          modifiedAt: skillAux.modifiedAt,
          name: skillAux.skillName,
          skillTypeId: skillAux.skillCategoryId,
          skillLevelId: skillAux.skillLevelId,
          skillLevel: '',
        }))
      );
    }
  }, [params.positionId]);

  useEffect(() => {
    onGetPositionById();
  }, [onGetPositionById]);

  useEffect(() => {
    if (positionId && formMode === FormModes.creating) {
      history.push(pathToRedirect);
    }
  }, [formMode, history, pathToRedirect, positionId]);

  useEffect(() => {
    const itemsAux = [
      {
        label: t('positions.positionInformation'),
        key: POSITION_TAB,
        children: (
          <NewPosition
            setIsTabDisabled={setIsTabDisabled}
            pathToRedirect={pathToRedirect}
            pathToRedirectOnCancel={pathToRedirectOnCancel}
            handleEditPosition={handleEditRequest}
            onPositionDataFinish={handleRequestAccordingToFormMode}
            positionForm={positionForm}
            formMode={formMode}
          />
        ),
      },
      {
        label: t('timetracker.people.skills'),
        key: SKILLS_KEY_TAB,
        children: (
          <>
            <Skills
              submitting={submitting}
              forMode={formMode}
              setTab={setTab}
              pathToRedirectOnCancel={pathToRedirectOnCancel}
              skills={selectedSkills}
              onChangeSelectedSkills={setSelectedSkills}
              positionForm={positionForm}
            />
          </>
        ),
      },
    ];
    setItems(itemsAux);
  }, [
    currentPosition,
    handleRequestAccordingToFormMode,
    formMode,
    submitting,
    setIsTabDisabled,
    handleEditRequest,
    handleSetAllSkills,
    onTabChange,
    pathToRedirect,
    positionForm,
    selectedSkills,
    setTab,
    t,
    tabs,
    pathToRedirectOnCancel,
  ]);

  const onChangeTab = useCallback(
    (data: string) => {
      if (!isTabDisabled) {
        setTab(parseInt(data, 10));
      }
    },
    [isTabDisabled, setTab]
  );

  return (
    <>
      <TitleStyled>
        {formMode === FormModes.edit
          ? `${t('common.edit')}: ${currentPosition?.positionName || ''}`
          : t('positions.newPosition')}
      </TitleStyled>
      <AddPositionContext.Provider
        value={{
          currentPosition,
          hasChanged,
          setHasChanged,
        }}
      >
        <Tabs
          type="card"
          defaultActiveKey={tabs.defaultTab.toString()}
          activeKey={tabs.activeTab.toString()}
          items={items}
          tabPosition="left"
          onChange={onChangeTab}
        />
      </AddPositionContext.Provider>
    </>
  );
};

export default memo(AddPositions);

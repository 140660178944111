export enum Endpoints {
  acceptAbsenceAsLeader = '/api/Absence/AcceptAsLeader/',
  acceptAbsenceAsReviewer = '/api/Absence/AcceptAsReviewer/',
  acceptPhoto = '/api/ProfilePicture/Approved',
  aceptTimeEntry = '/api/TimeEntry/Accept/',
  aceptTimeEntryLeader = '/api/TimeEntry/AcceptAsLeader/',
  activateInterviewer = '/api/Placement/Interviewer/Activate',
  activatePerson = '/api/Person/Active',
  activateProject = '/api/Project/Activate',
  activateUserProfileArea = '/api/UserProfileArea/ActivateUserProfileArea/',
  activeCompanyGroup = '/api/CompanyGroup/ActivateCompanyGroup',
  activeCompanySetting = '/api/CompanySetings/activateCompanySettings',
  activeEmploymentData = '/api/EmploymentData/Active',
  activeProfile = '/api/Profile/Active',
  activeProjectExternalId = '/api/ProjectExternal/ActivateProjectExternal',
  activeProjectPerson = '/api/ProjectPerson/Activate',
  activeSummary = '/api/Summary/Active',
  activeSummaryByOwner = '/api/Summary/ActiveByOwner',
  addAbsence = '/api/Absence/Add',
  addAdminUser = '/api/Users/CompanyAdmin',
  addApprover = '/api/Approver/Add',
  addBilling = '/api/EmploymentDataInvoices/Add',
  addCandidate = '/api/Placement/Candidate/Add',
  AddCompany = '/api/Company/add',
  addCompanyGroup = '/api/CompanyGroup/AddCompanyGroup',
  addCompanySetting = '/api/CompanySetings/add',
  addContract = '/api/ContractType/AddAsCompanyAdministrator',
  addEducation = '/api/Education/Add',
  AddEducationByUser = '/api/Education/AddByOwner',
  addEmploymentData = '/api/EmploymentData/Add',
  addEmploymentDataList = '/api/EmploymentData/Add',
  addExperience = '/api/Experience/',
  addExperienceByUser = '/api/Experience/AddByOwner',
  addInterviewer = '/api/Placement/Interviewer/Add',
  addNewPersonToProject = '/api/ProjectPerson/AddNewPersonToProject',
  addNewProjectToPerson = '/api/ProjectPerson/AddNewProjectToPerson',
  addProfile = '/api/Profile',
  addProjectExternalId = '/api/ProjectExternal/AddProjectExternalToProject',
  addSkills = '/api/Skill/Add',
  addSkillsByUserId = '/api/Skill/AddByOwner',
  addSummary = '/api/Summary',
  addSummaryByOwner = '/api/Summary/AddByOwner',
  addTypeOfHours = '/api/HoursTypes/Add',
  addUser = '/api/Users/',
  addUserProfile = '/api/UserProfile/AddUserProfile',
  addUserProfileArea = '/api/UserProfileArea/Add',
  amendAbsenceAsLeader = '/api/Absence/AmendAsLeader/',
  amendAbsenceAsReviewer = '/api/Absence/AmendAsReviewer/',
  amendPhoto = '/api/ProfilePicture/Amend',
  amendTimeEntry = '/api/TimeEntry/Amend/',
  amendTimeEntryLeader = '/api/TimeEntry/AmendAsLeader/',
  cancelAbsence = '/api/Absence/Cancel',
  cancelAbsenceAsReviewer = '/api/Absence/CancelAsReviewer',
  changePasswordFirstTime = '/api/Users/changepasswordfirstlogging',
  changePasswordFromEmail = '/api/Users/changeforgottenpasswordbyuseremail',
  changeUserPassword = '/api/Users/changepassword',
  clearNotifications = '/api/Notification/MarkAllAsRead',
  companySecurityKey = '/api/CompanySecuritySettings',
  createContractHoursTypes = '/api/ContractTypeHoursType/Add',
  deleteApprover = '/api/Approver/Delete',
  deleteBillingInvoice = '/api/EmploymentDataInvoices',
  deleteClient = '/api/Client/',
  deleteCompany = '/api/Company/Delete',
  deleteCompanyGroup = '/api/CompanyGroup/DeleteCompanyGroup',
  deleteCompanySetting = '/api/CompanySetings/delete',
  deleteContract = '/api/ContractType/DeleteAsCompanyAdministrator/:contractTypeId',
  deleteEducation = '/api/Education/Delete',
  deleteEducationByOwner = '/api/Education/DeleteByOwner',
  deleteEmploymentData = '/api/EmploymentData',
  deleteExperience = '/api/Experience/Delete',
  deleteExperienceByOwner = '/api/Experience/DeleteByOwner',
  deleteHourType = '/api/HoursTypes/Delete/:id',
  deleteInterviewer = '/api/Placement/Interviewer/Delete',
  deleteOpenPosition = '/api/OpenPosition/DisablePosition',
  deletePerson = '/api/Person/',
  deleteProfile = '/api/Profile',
  deleteProject = '/api/Project/',
  deleteProjectByPerson = '/api/ProjectPerson/',
  deleteProjectExternalId = '/api/ProjectExternal/DeleteProjectExternal',
  deleteSummary = '/api/Summary/Delete',
  deleteSummaryByOwner = '/api/Summary/DeleteByOwner',
  disableMainProjectPersonFromPeople = '/api/ProjectPerson/DisableMainProjectFromPeople',
  disableMainProjectPersonFromProject = '/api/ProjectPerson/DisableMainProjectFromProject',
  disableStatusOpenPosition = '/api/Placement/OpenPosition/DisablePosition',
  editAbsence = '/api/Absence/Edit',
  editAbsenceAsReviewer = '/api/Absence/EditAsReviewer',
  editApprover = '/api/Approver/edit',
  editBilling = '/api/EmploymentDataInvoices/Update',
  editCandidate = '/api/Placement/Candidate/Edit/',
  editClient = '/api/Client/',
  EditCompanyGroup = '/api/CompanyGroup/EditCompanyGroup',
  editEducation = '/api/Education/Edit',
  editEducationByUserId = '/api/Education/EditByOwner',
  editEmploymentDataList = '/api/EmploymentData/Update',
  editExperience = '/api/Experience/Edit',
  editExperienceByuser = '/api/Experience/EditByOwner',
  editInterviewer = '/api/Placement/Interviewer/Update',
  editOpenPosition = '/api/Placement/OpenPosition/EditPosition',
  editPerson = '/api/Person/',
  editPersonByUser = '/api/Person/EditByOwner',
  editPersonPutFromProject = '/api/ProjectPerson/PutFromProject/',
  editProfile = '/api/Profile',
  editProfileStatus = '/api/UserProfile/EditStatusById',
  editProject = '/api/Project/',
  editProjectExternalId = '/api/ProjectExternal/EditProjectExternal',
  editProjectPerson = '/api/ProjectPerson/',
  editSummary = '/api/Summary',
  editSummaryByOwner = '/api/Summary/EditByOwner',
  editTimesheetByReviewer = '/api/TimeSheet/GetByIdToEditForReviewer',
  editTypeOfHour = '/api/HoursTypes/Update',
  enableMainProjectPersonFromPeople = '/api/ProjectPerson/EnableMainProjectFromPeople',
  enableMainProjectPersonFromProject = '/api/ProjectPerson/EnableMainProjectFromProject',
  getAbsensesByStatusProjectAndDates = '/api/Absence/GetAllAsLeader/',
  getActiveClients = '/api/Client/GetAllActive',
  getAllAbsences = '/api/Absence/GetById',
  getAllAbsensesAsReviewer = '/api/Absence/GetAllAsReviewer/',
  GetAllActivitiesByProjectId = '/api/ProjectActivity/GetAllByProjectId',
  getAllApprovers = '/api/Approver/GetAll',
  getAllAsAdminCompany = '/api/TimeEntry/GetAllAsAdminCompany/',
  getAllAsLeader = '/api/TimeEntry/GetAllAsLeader/',
  getAllAsReviewer = '/api/TimeEntry/GetAllAsReviewer/',
  getAllBillableRolesFiltered = '/api/BillableRole/getAllBillableRolesFiltered',
  getAllCompanyGroups = '/api/CompanyGroup/GetAll',
  getAllCompanySettings = '/api/CompanySetings/getallfilter',
  getAllNotificationsByPersonId = '/api/Notification/GetAll',
  getAllPeopleAsReviewer = '/api/Person/GetPeopleWhoIsActive',
  getAllPeopleByProjectFiltered = '/api/ProjectPerson/GetAllPeopleByProjectFiltered/',
  getAllPeopleFilteredNotInProject = '/api/Person/GetPeopleAvailableToProject',
  getAllPeopleNotInProject = '/api/ProjectPerson/GetAllPeopleNotInProject/',
  getAllProfilesFilter = '/api/Profile/GetAllFilter',
  getAllProjectByAdminCompany = '/api/Project/GetAllProjectsByAdminCompany',
  getAllProjectByReviewer = '/api/Project/GetAllProjectsByReviewer',
  getAllProjectsByCompanyId = '/api/Project/GetAllProjectByCompanyId',
  getAllProjectsNotInPerson = '/api/ProjectPerson/GetAllProjectsNotExistInPerson/',
  getAllSections = '/api/Section',
  getAllTimeEntriesHistory = '/api/TimeEntryHistory/GetAllFilter/',
  getAllTimeSheets = '/api/TimeSheet/GetAllFilter',
  getAllTypesOfHours = '/api/HoursTypes/GetAllFiltered',
  getAllUserProfileAreaByUser = '/api/UserProfileArea/GetAllFilter',
  getApproverData = '/api/Approver/Get',
  getAreasNotAssignedToUser = '/api/Area/GetAreasNotAssignedToUser',
  getAttachment = '/api/Absence/Download/',
  getAvailableApprovers = '/api/Person/GetPeopleAvailableToBeApprovers',
  getAvailableInterviewers = '/api/Placement/Interviewer/GetAvailablePeopleAsInterviewer',
  getBillableRoles = '/api/BillableRole/GetBillableRole/',
  getBillingInvoice = '/api/EmploymentDataInvoices',
  getBillingPeriod = '/api/BillingPeriod',
  getCandidateById = '/api/Placement/Candidate/GetById/',
  getCandidatesList = '/api/Placement/Candidate/GetAllFiltered',
  getCandidateStatusList = '/api/Placement/CandidateStatus/GetAllFiltered',
  getCatalogues = '/api/Catalogue/GetAllAsCompanyAdministratorFiltered',
  getHoursTypesByPerson = '/api/ContractTypeHoursType/GetHourTypesByPerson/:personId/:startDate/:endDate',
  getCategories = '/api/Category',
  getCitiesByState = '/api/City/GetCities/',
  getClientById = '/api/Client/Client/',
  getClients = '/api/Client/clients',
  getClientsInfo = '/api/Client/',
  getColombiaCities = '/api/City/GetColombiaCities/',
  getCompanies = '/api/Company/getallfilter',
  getCompaniesByUser = '/api/Company/getActiveCompaniesByUser',
  getCompanyById = '/api/Company/GetById',
  getCompanyGroup = '/api/CompanyGroup/GetCompanyGroup',
  getCompanyPermissions = '/api/CompanyPermission/GetPermissionsByCompany/:companyId',
  getCompanySetting = '/api/CompanySetings',
  getCompanySettings = '/api/CompanyCommunicationSettings/ByCompanyId',
  getContractHoursTypes = '/api/ContractTypeHoursType/GetContractHoursTypesByContract',
  getContractHourTypeById = '/api/ContractTypeHoursType/GetContractHourType/',
  getContracts = '/api/ContractType/GetAllAsCompanyAdministratorFiltered',
  getContractTypeById = '/api/ContractType/GetById/:contractTypeId',
  getContractTypes = '/api/ContractType/GetByCompany',
  getCountries = '/api/Country',
  getCurrencies = '/api/Currency',
  getEducationById = '/api/Education/GetAllByPersonId',
  getEducationByUserId = '/api/Education/GetAllByOwner',
  getEducationStatus = '/api/Education/GetEducationStatus',
  getEducationType = '/api/Education/GetEducationType',
  getEmploymentDataById = '/api/EmploymentData',
  getEmploymentDataByOwner = '/api/EmploymentData/GetPersonByOwner/',
  getEmploymentDataByPersonId = '/api/EmploymentData/GetByPersonId/',
  getEmploymentDataList = '/api/EmploymentData/GetAllFiltered',
  getExperienceById = '/api/Experience/GetAllByPersonId',
  getExperienceByUser = '/api/Experience/GetAllByOwner',
  getExpertise = '/api/Placement/Expertise/GetAllAsync',
  getFilterAllNotifications = '/api/Notification/GetAllFilter',
  getGenders = '/api/Gender',
  getHoursTypes = '/api/HoursTypes/GetHoursTypes',
  getHourTypeById = '/api/HoursTypes/GetHourTypeById/:id',
  getIdentificationTypes = '/api/IdentificationType/GetIdentificationType',
  getInterviewerById = '/api/Placement/Interviewer/GetById',
  getInterviewers = '/api/Placement/Interviewer/GetAllFiltered',
  getInvoices = '/api/EmploymentDataInvoices/GetByEmploymentDataId',
  getJobPositions = '/api/JobPosition/GetJobPositions',
  getJobPositionsByCategory = '/api/JobPosition/GetByCategory',
  getLastContractDates = '/api/EmploymentData/GetLastContractDates',
  getLoggedUser = '/api/Users/getLoggedUser',
  getMaxDailyWorkingHours = '/api/CompanySetings/GetMaxDailyWorkHours',
  getNews = '/api/News/GetAllFiltered',
  getNextAndPreviousBillingInvoice = '/api/EmploymentDataInvoices/GetPreviusAndNextById',
  getNextAndPrevius = '/api/EmploymentData/GetNextAndPrevius',
  getOpenPositionById = '/api/Placement/OpenPosition/GetById',
  getOpenPositionsByCompany = '/api/Placement/OpenPosition/GetOpenPositions',
  getPathPermissions = '/api/UserProfile/GetPermissionsByUserProfile/',
  getPeople = '/api/Person/People',
  getPeopleInCompanyGroup = '/api/Person/GetPeopleWhoIsActiveInCompanyGroup',
  getPerson = '/api/Person/',
  getPersonByEmail = '/api/Person/PersonByEmail',
  getPersonByOwner = '/api/Person/GetByOwner',
  getPersonByUserId = '/api/Person/PersonByUserId',
  getPersonLastAssignment = '/api/ProjectPerson/GetAvailableAssignmentDates',
  getPersonLeader = '/api/ProjectPerson/GetPersonLeader',
  getPersonPhoto = '/api/ProfilePicture/GetByPersonId',
  getPhoto = '/api/ProfilePicture/GetByOwner',
  getPhotos = '/api/profilePicture/GetAllFiltered',
  getPlacementPositionByName = '/api/Placement/OpenPositionSkill/GetSkillNames',
  getPlacementPositionClientName = '/api/Placement/ClientsPosition/GetClientsName',
  getPlacementPositionProject = '/api/Placement/ProjectsPosition/GetProjectsName',
  getPlacementPositionRoles = '/api/Placement/PlacementRoles/GetRoles',
  getPlacementPositionStatusByCompany = '/api/Placement/ReasonStatus/GetAllByCompany',
  getPlacementPositionTypes = '/api/Placement/PositionType/GetPositionType',
  getPlacementProjects = '/api/Placement/ProjectsPosition/GetProjectPositions',
  getPlacementSkillCategories = '/api/Placement/SkillCategory/GetSkillCategories',
  getPlacementSkillLevel = '/api/Placement/PlacementSkillLevel/GetBySkillType',
  getPositionSeniority = '/api/Placement/PlacementSeniorities/GetSeniority',
  getPositionTypes = '/api/Placement/PositionType/GetPositionType',
  getProfileById = '/api/Profile',
  getProfilesNotInUser = '/api/UserProfile/GetProfilesNotInUser',
  getProjectArea = '/api/Area',
  getProjectById = '/api/Project/',
  getProjectByProjectIdClientId = '/api/Project/Project/',
  getProjectExternalIds = '/api/ProjectExternal/GetAllByProjectId',
  getProjectInfo = '/api/Project/',
  getProjectPerson = '/api/ProjectPerson/GetProjectPerson',
  getProjectRoles = '/api/ProjectRole/GetProjectRoles',
  getProjects = '/api/Project/Projects',
  getProjectsByClient = '/api/Project/Client/',
  getProjectsByPerson = '/api/ProjectPerson/GetProjectsByPersonFiltered/',
  getProjectsByPersonFromPeople = '/api/ProjectPerson/GetProjectsByPersonFilteredFromPerson',
  getProjectsByPersonFromProjects = '/api/ProjectPerson/GetProjectsByPersonFilteredFromProject',
  getProjectsFromLeader = '/api/Project/Rol/',
  getProjectsWhereLeader = '/api/Project/GetInformationProjectsInWhichPersonIsLeader',
  getReasons = '/api/Reason',
  getReportTime = '/api/Report/GetTestExcel',
  getReportTimeOff = '/api/Report/GetReportTimeOff',
  getReportTimeWithActivities = '/api/Report/GetReportWithActivities',
  getReportWorkedHours = '/api/Report/GetReportWorkedHours',
  getReportWithHoursType = '/api/Report/GetReportWithHoursType',
  getResume = '/api/Resume/GetByOwner',
  getResumeByUserId = '/api/Resume',
  getSalesChannels = '/api/Placement/Sale/GetSale',
  getSeniorities = '/api/Seniority/GetSeniority',
  getSettingByName = '/api/Setting/',
  getSingleAbsence = '/api/Absence/GetById',
  getSkillsById = '/api/Skill/GetByPersonId',
  getSkillsByType = '/api/Skill/Search',
  getSkillsByUserId = 'api/Skill/GetByOwner',
  getSkillsLevel = '/api/SkillLevel/GetBySkillType',
  getSkillTypes = '/api/SkillTypes/GetSkillsTypes',
  getStatesByCountry = '/api/State/GetStates/',
  getStatuses = '/api/Status/GetAll',
  getSummaryById = '/api/Summary/GetAllByPersonId',
  getSummaryByOwner = '/api/Summary/GetAllByOwner',
  getTimeEntryById = '/api/TimeEntry/GetById/',
  getTimeEntryByIdLeader = '/api/TimeEntry/GetByIdAsLeader/',
  getTimeOffHistory = '/api/AbsenceHistory/GetAll',
  getTimeSheet = '/api/TimeSheet/GetTimeSheet',
  getTimeSheetById = '/api/TimeSheet/GetById',
  getTimeSheetByIdToEdit = '/api/TimeSheet/GetByIdToEdit/',
  getUserById = '/api/Users/getuserbyid/',
  getUserProfiles = '/api/UserProfile/GetAllFilter',
  getUsers = '/api/Users/getallfilter',
  getWorkingHoursPerWeek = '/api/EmploymentData/getWorkingHoursPerWeek/',
  hideNotification = '/api/Notification/Hide',
  importTimesheet = '/api/TimeSheet/UploadTimeSheetFile',
  inactivateInterviewer = '/api/Placement/Interviewer/Inactivate',
  inactivatePerson = '/api/Person/Inactive',
  inactivateProject = '/api/Project/Inactivate',
  inactivateUserProfileArea = '/api/UserProfileArea/InactivateUserProfileArea/',
  inactiveCompanyGroup = '/api/CompanyGroup/DeactivateCompanyGroup',
  inactiveCompanySetting = '/api/CompanySetings/deactivateCompanySettings',
  inactiveEmploymentData = '/api/EmploymentData/Inactive',
  inactiveProfile = '/api/Profile/Inactive',
  inactiveProjectExternalId = '/api/ProjectExternal/DeactivateProjectExternal',
  inactiveProjectPerson = '/api/ProjectPerson/Inactivate',
  inactiveSummary = '/api/Summary/Inactive',
  inactiveSummaryByOwner = '/api/Summary/InactiveByOwner',
  isProjectEditable = '/api/ProjectPerson/IsLastAssignation/',
  localById = '/api/Language/gettranslationsperlanguage/',
  locales = '/api/Language/getlanguages',
  login = '/api/Users/authenticate',
  manageTimesheets = '/api/TimeSheet/GetAllFilterReviewer',
  readNotification = '/api/Notification/MarkAsRead',
  saveBillableRole = '/api/BillableRole/Save',
  saveClient = '/api/Client',
  saveOpenPosition = '/api/Placement/OpenPosition/Save',
  savePerson = '/api/Person/Save',
  saveProject = '/api/Project',
  saveTimeSheet = '/api/TimeSheet/EditTimeSheet',
  saveTimesheetByReviewer = '/api/TimeSheet/EditTimeSheetForReviewer',
  sendEmailPasswordRecovery = '/api/Users/RequestChangeForgotPassword',
  submitTimeSheet = '/api/TimeSheet/SubmitForApproval',
  submitTimesheetForReviewer = '/api/TimeSheet/SubmitForApprovalForReviewer',
  transferPerson = '/api/TransferPeople/Transfer',
  updateAbsenceComments = '/api/Absence/UpdateComments',
  updateBillableRole = '/api/BillableRole/UpdateBillableRole',
  updateBillableRoleStatus = '/api/BillableRole/UpdateIsActiveById',
  UpdateCompanyFromAdministration = '/api/Company/UpdateFromAdministration',
  UpdateCompanyFromConfiguration = '/api/Company/UpdateFromConfiguration',
  updateCompanyIsActive = '/api/Company/updateIsActive/',
  updateCompanyPermissions = '/api/CompanyPermission',
  updateCompanySetting = '/api/CompanySetings/update',
  UpdateCompanySettings = '/api/CompanyCommunicationSettings',
  updateContract = '/api/ContractType/UpdateAsCompanyAdministrator',
  updateContractHoursTypes = '/api/ContractTypeHoursType/Update',
  updateEmploymentData = '/api/EmploymentData/Update',
  updateOpenPositionComment = '/api/Placement/OpenPosition/UpdateComments',
  updateTimeEntryComments = '/api/TimeEntry/UpdateComments',
  updateUserIsActive = '/api/Users/updateIsActive/',
  updateUserIsLocked = '/api/Users/updateIsLocked/',
  uploadApprovedPhoto = '/api/ProfilePicture/UploadApproved',
  uploadPhoto = '/api/profilePicture',
  validateEmail = '/api/Person/ValidateEmail',
  validateRecoveryPasswordToken = '/api/Users/ValidateForgottenPasswordToken',
  viewTimesheetByReviewer = '/api/TimeSheet/GetByIdForReviewer',
  voidedTimeEntry = '/api/TimeEntry/Voided',
}

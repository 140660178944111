import React, {
  FC,
  useCallback,
  useEffect,
  Suspense,
  lazy,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  RouteProps,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { setUser, removeUser } from 'store/auth/auth.actions';
import {
  SESSION_USER,
  CURRENT_LOCALE,
  COMPANY_ID,
  LANGUAGE_ID,
  DEFAULT_LOCALE,
  ES_LOCALE_ID,
  ProfileOptions,
  USER_PATHS,
} from 'shared/constants/common.const';
import {
  removeFromSessionStorage,
  getFromSessionStorage,
  getFromLocalStorage,
  setToLocalStorage,
  setToSessionStorage,
} from 'shared/utils/storage.utils';
import { redirectToPath, showNotification } from 'shared/utils/common.utils';
import { loadLocales, setCurrentLocalData } from 'store/locale/locale.actions';
import {
  selectCurrentLocal,
  selectLocales,
} from 'store/locale/locale.selectors';
import { userService } from 'shared/services/user.service';
import { showError } from 'shared/utils/error.utils';
import { useTranslation } from 'react-i18next';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { fetchLocaleById } from 'store/locale/locale.helpers';
import CookieNotice from 'components/CookieNotice';
import BaseLayout from 'features/Layouts';
import { HOME_PAGE_LAYOUT, NOUSER_PAGE_LAYOUT } from 'features/Layouts/helpers';
import { apiService } from 'shared/services';
import { ConfigProvider, Spin } from 'antd';
import moment from 'moment';
import esES from 'antd/lib/locale/es_ES';
import 'moment/locale/es';
import 'moment/locale/en-ca';
import { selectCurrentCompany } from 'store/auth/auth.selectors';
import TimetrackerRoutes from './Timetracker';
import ConfigurationsRoutes from './Configurations';
import HumanManagementRoutes from './HumanManagement';
import PositionsRoutes from './Positions';
import { APP_PATH, MOMENT_ES_LOCALE_CONFIG, ACTUAL_ACCOUNT } from './helpers';
import { ContainerDiv } from './styles';
import OpenPositionProvider from '../Modules/OpenPositions/provider';
import AdministrationRoutes from './Administration';

const ErrorPage = lazy(() => import('features/ErrorPage'));
const LoginPage = lazy(() => import('features/LoginPage'));
const HomePage = lazy(() => import('features/HomePage'));
const NoUserPage = lazy(() => import('features/NoUserPage'));
const PrivacyPolicy = lazy(() => import('features/PrivacyPolicy'));
const Terms = lazy(() => import('features/Terms'));
const ProtectedRoute = lazy(() => import('features/Routes/ProtectedRoute'));
const GuardedRoute = lazy(() => import('features/Routes/GuardedRoute'));
const ManageAccount = lazy(() => import('features/Routes/ManageAccount'));

const UserRoutes: FC<RouteProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const languages = useSelector(selectLocales);
  const isAuthenticated = useIsAuthenticated();
  const currentLocal = useSelector(selectCurrentLocal);
  const { t } = useTranslation();
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const isPolicyPath = pathname === '/policy' || pathname === '/terms';
  const { instance, accounts } = useMsal();

  if (currentLocal?.id === ES_LOCALE_ID) {
    moment.updateLocale('es', MOMENT_ES_LOCALE_CONFIG);
  } else {
    moment.updateLocale('en-ca', MOMENT_ES_LOCALE_CONFIG);
  }

  const removeUserAndRedirect = useCallback(() => {
    removeFromSessionStorage(SESSION_USER);
    removeFromSessionStorage(USER_PATHS);
    dispatch(removeUser());
  }, [dispatch]);

  const getLoggedUser = useCallback(
    (token: string) => {
      apiService.setToken(token);

      userService
        .getLoggedUser()
        .then((response) => {
          const user = { ...response, token };
          setToSessionStorage(SESSION_USER, user);
          dispatch(setUser(user));
        })
        .then(() => history.push(APP_PATH.HOME))
        .catch((errorPromise: Promise<any>) => {
          errorPromise
            .then((errorJson) => JSON.parse(errorJson))
            .then((error: AppError.CatchError) => {
              if (error.status === 404) history.push(APP_PATH.NOUSER);
              if (error.status === 400) showError(error, t);
            })
            .catch(() => showNotification(t, null, true));
        });
    },
    [dispatch, history, t]
  );

  useEffect(() => {
    instance.handleRedirectPromise().then((response) => {
      if (response !== null) {
        getLoggedUser(response?.accessToken);
      }
    });
  }, [getLoggedUser, instance]);

  useEffect(() => {
    if (
      accounts &&
      accounts[ACTUAL_ACCOUNT] &&
      accounts[ACTUAL_ACCOUNT].idTokenClaims
    ) {
      const actualTime = Math.floor(Date.now() / 1000);
      const expirationTokenTime =
        typeof accounts[ACTUAL_ACCOUNT].idTokenClaims?.exp !== 'undefined'
          ? accounts[ACTUAL_ACCOUNT].idTokenClaims?.exp
          : 0;
      let remainingTokenExpTime = 0;

      if (expirationTokenTime !== undefined) {
        remainingTokenExpTime = expirationTokenTime - actualTime;
      }

      if (isAuthenticated && remainingTokenExpTime < 0) {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        });
        removeUserAndRedirect();
      }
    }
  }, [instance, pathname, isAuthenticated, accounts, removeUserAndRedirect]);

  useEffect(() => {
    const user = getFromSessionStorage(SESSION_USER);
    const companyId = getFromSessionStorage(COMPANY_ID);
    const languageId = getFromLocalStorage(LANGUAGE_ID);

    if (user) {
      dispatch(setUser(user));
    } else if (!isPolicyPath && !isAuthenticated) {
      redirectToPath(history, APP_PATH.LOGIN);
    }

    if (companyId) {
      apiService.setCompanyId(companyId);
    }

    if (languageId) {
      apiService.setLanguageId(languageId);
    }

    dispatch(loadLocales());
  }, [dispatch, history, isPolicyPath, isAuthenticated]);

  useEffect(() => {
    const user = getFromSessionStorage(SESSION_USER);
    if (
      !user &&
      pathname !== '/login' &&
      pathname !== '/home' &&
      pathname !== '/' &&
      pathname !== '/policy' &&
      pathname !== '/terms' &&
      pathname !== '/nouser'
    ) {
      const url = `${APP_PATH.LOGIN + (search || '?')}&redirectTo=${pathname}`;
      redirectToPath(history, url);
    }
  }, [pathname, search, history]);

  useEffect(() => {
    const currentLocale = getFromLocalStorage(CURRENT_LOCALE);

    const locale = currentLocale
      ? languages?.find((lang) => lang.uiCulture === currentLocale)
      : DEFAULT_LOCALE;

    if (locale) {
      fetchLocaleById(locale, setIsLoadingKeys);
      dispatch(setCurrentLocalData(locale));
      apiService.setLanguageId(String(locale.id));
      setToLocalStorage(LANGUAGE_ID, String(locale.id));
    }
  }, [dispatch, languages]);

  const RootPath = useCallback(() => <Redirect to={APP_PATH.LOGIN} />, []);
  const HomePath = useCallback(
    () => (
      <BaseLayout config={HOME_PAGE_LAYOUT} isHome>
        <HomePage />
      </BaseLayout>
    ),
    []
  );
  const ProtectedPath = useCallback(
    () => <ProtectedRoute component={HomePath} />,
    [HomePath]
  );
  const TimetrackerPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={APP_PATH.TIMETRACKER}
        component={() => <TimetrackerRoutes />}
        option={ProfileOptions.section}
      />
    ),
    []
  );
  const ConfigurationPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={APP_PATH.CONFIGURATIONS}
        component={() => <ConfigurationsRoutes />}
        option={ProfileOptions.section}
      />
    ),
    []
  );
  const HumanManagementPath = useCallback(
    () => (
      <GuardedRoute
        pathToCheck={APP_PATH.HUMAN_MANAGEMENT}
        component={() => <HumanManagementRoutes />}
        option={ProfileOptions.section}
      />
    ),
    []
  );
  const PositionsPath = useCallback(
    () => (
      <OpenPositionProvider>
        <GuardedRoute
          pathToCheck={APP_PATH.POSITIONS}
          component={() => <PositionsRoutes />}
          option={ProfileOptions.section}
        />
      </OpenPositionProvider>
    ),
    []
  );
  const ErrorPath = useCallback(() => <ErrorPage />, []);
  const LoginPath = useCallback(() => <LoginPage />, []);
  const PrivacyPolicyPath = useCallback(
    () => (
      <BaseLayout config={NOUSER_PAGE_LAYOUT} isHome>
        <PrivacyPolicy />
      </BaseLayout>
    ),
    []
  );
  const TermsPath = useCallback(
    () => (
      <BaseLayout config={NOUSER_PAGE_LAYOUT} isHome>
        <Terms />
      </BaseLayout>
    ),
    []
  );

  const NoUserPath = useCallback(
    () => (
      <BaseLayout config={NOUSER_PAGE_LAYOUT} isHome>
        <NoUserPage />
      </BaseLayout>
    ),
    []
  );
  const ManageAccountPath = useCallback(() => <ManageAccount />, []);
  const AdministrationPath = useCallback(() => <AdministrationRoutes />, []);
  const WildCardPath = useCallback(() => <Redirect to={APP_PATH.LOGIN} />, []);
  const companyId = useSelector(selectCurrentCompany);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (!query.get('companyId')) {
      query.set('companyId', companyId);
      history.replace({
        search: query.toString(),
      });
    }
  }, [history.location.search, history, companyId]);

  return (
    <>
      <CookieNotice />
      <ConfigProvider
        locale={currentLocal?.id === ES_LOCALE_ID ? esES : undefined}
        getPopupContainer={(triggerNode: HTMLElement | undefined) =>
          (triggerNode?.parentNode as HTMLElement) || document.body
        }
      >
        {isLoadingKeys ? (
          <ContainerDiv>
            <Spin />
          </ContainerDiv>
        ) : (
          <Suspense fallback={<Spin />}>
            <Switch>
              <Route
                key={APP_PATH.LOGIN}
                exact={false}
                path={APP_PATH.LOGIN}
                render={LoginPath}
              />
              <Route
                key={APP_PATH.ROOT}
                exact
                path={APP_PATH.ROOT}
                render={RootPath}
              />
              <Route
                key={APP_PATH.HOME}
                exact
                path={APP_PATH.HOME}
                render={ProtectedPath}
              />
              <Route
                key={APP_PATH.TIMETRACKER}
                path={APP_PATH.TIMETRACKER}
                render={TimetrackerPath}
              />

              <Route
                key={APP_PATH.POLICY}
                path={APP_PATH.POLICY}
                render={PrivacyPolicyPath}
              />

              <Route
                key={APP_PATH.TERMS}
                path={APP_PATH.TERMS}
                render={TermsPath}
              />

              <Route
                key={APP_PATH.NOUSER}
                path={APP_PATH.NOUSER}
                render={NoUserPath}
              />
              <Route
                key={APP_PATH.CONFIGURATIONS}
                path={APP_PATH.CONFIGURATIONS}
                render={ConfigurationPath}
              />
              <Route
                key={APP_PATH.HUMAN_MANAGEMENT}
                path={APP_PATH.HUMAN_MANAGEMENT}
                render={HumanManagementPath}
              />
              <Route
                key={APP_PATH.POSITIONS}
                path={APP_PATH.POSITIONS}
                render={PositionsPath}
              />
              <Route
                key={APP_PATH.ERROR}
                exact
                path={[APP_PATH.ERROR]}
                render={ErrorPath}
              />
              <Route
                exact
                key={APP_PATH.MANAGE_ACCOUNT}
                path={APP_PATH.MANAGE_ACCOUNT}
                render={ManageAccountPath}
              />
              <Route
                key={APP_PATH.ADMINISTRATION}
                path={APP_PATH.ADMINISTRATION}
                render={AdministrationPath}
              />
              <Route
                key={APP_PATH.WILDCARD}
                path={APP_PATH.WILDCARD}
                render={WildCardPath}
              />
            </Switch>
          </Suspense>
        )}
      </ConfigProvider>
    </>
  );
};

export default UserRoutes;

import React, { forwardRef } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { FormInstance } from 'antd';
import { TableWrapper } from './styles';
import ActionsAntTable from '..';
import { useActionTable } from '../hooks';
import { ActionsConfig, RefObject } from '../helpers';

const FullTable = forwardRef(
  (
    props: {
      columns: ColumnsType<any>;
      data: any[];
      paginationName: string;
      /** It's obtained by using useActionTable hook */
      tableConfig: ReturnType<typeof useActionTable>;
      form?: FormInstance;
      actionsConfig?: ActionsConfig;
      isLoading?: boolean;
      id?: string;
      hideShadow?: boolean;
      noPadding?: boolean;
      noDataComponent?: JSX.Element;
    },
    ref: React.Ref<RefObject> | undefined
  ) => {
    const {
      columns,
      data,
      paginationName,
      tableConfig,
      form,
      actionsConfig,
      isLoading,
      id,
      hideShadow,
      noPadding,
      noDataComponent,
    } = props;

    const { Paginator } = tableConfig;

    return (
      <TableWrapper noPadding={noPadding} hideShadow={hideShadow} id={id}>
        <ActionsAntTable
          ref={ref}
          columns={columns}
          data={data}
          scroll={{ x: 'max-content' }}
          form={form}
          actionsConfig={actionsConfig}
          isLoading={isLoading}
          noDataComponent={noDataComponent}
        />
        <Paginator name={paginationName} />
      </TableWrapper>
    );
  }
);

FullTable.defaultProps = {
  form: undefined,
  actionsConfig: undefined,
  isLoading: false,
  id: undefined,
  hideShadow: false,
  noPadding: false,
  noDataComponent: undefined,
};

export default FullTable;

import { Endpoints } from 'shared/constants/endpoints.const';
import { CompanyGroupRecordType } from 'features/Modules/Administration/CompanyGroups/helpers';
import { apiService } from './api.service';

class CompanyGroupService {
  getCompanyGroups = (
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: {}
  ): Promise<DTO.GridResults<DTO.CompanyGroup>> => {
    return apiService.request({
      url: Endpoints.getAllCompanyGroups,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'Name',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  GetCompanyGroupById = (
    id: DTO.CompanyGroup['id']
  ): Promise<DTO.ActionResult<DTO.CompanyGroup>> => {
    return apiService.request({
      url: `${Endpoints.getCompanyGroup}/${id}`,
      options: {
        method: 'GET',
      },
    });
  };

  AddCompanyGroup = (companyGroup: CompanyGroupRecordType) => {
    return apiService.request({
      url: Endpoints.addCompanyGroup,
      options: {
        method: 'POST',
        body: JSON.stringify(companyGroup),
      },
    });
  };

  EditCompanyGroup = (companyGroup: CompanyGroupRecordType) => {
    return apiService.request({
      url: Endpoints.EditCompanyGroup,
      options: {
        method: 'POST',
        body: JSON.stringify(companyGroup),
      },
    });
  };

  updateCompanyGroupIsActive = (
    companyGroupId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: `${
        isActive ? Endpoints.activeCompanyGroup : Endpoints.inactiveCompanyGroup
      }/${companyGroupId}`,
      options: {
        method: 'PATCH',
      },
    });
  };

  deleteCompanyGroup = (companyGroupId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteCompanyGroup}/${companyGroupId}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const companyGroupService = new CompanyGroupService();

import React, { createContext, useState, useCallback, useEffect } from 'react';

interface ExpertiseOption {
  value: string;
  label: string;
}

interface InterviewerContextType {
  expertiseOptions: ExpertiseOption[];
  updateExpertiseOptions: ((newExpertise: string) => void) | null;
  expertiseFilter: string[];
  setExpertiseFilter: React.Dispatch<React.SetStateAction<string[]>>;
}

export const InterviewerContext = createContext<InterviewerContextType>({
  expertiseOptions: [],
  updateExpertiseOptions: null,
  expertiseFilter: [],
  setExpertiseFilter: () => null,
});

interface InterviewerProviderProps {
  children: React.ReactNode;
  initialExpertise: DTO.Expertise[];
}

export const InterviewerProvider: React.FC<InterviewerProviderProps> = ({
  children,
  initialExpertise,
}) => {
  const [expertiseOptions, setExpertiseOptions] = useState<ExpertiseOption[]>(
    []
  );
  const [expertiseFilter, setExpertiseFilter] = useState<string[]>([]);

  useEffect(() => {
    setExpertiseOptions(
      initialExpertise.map((exp) => ({ value: exp.name, label: exp.name }))
    );
  }, [initialExpertise]);

  const updateExpertiseOptions = useCallback((newExpertise: string) => {
    setExpertiseOptions((prevOptions) => {
      if (!prevOptions.some((option) => option.value === newExpertise)) {
        return [...prevOptions, { value: newExpertise, label: newExpertise }];
      }
      return prevOptions;
    });
  }, []);

  return (
    <InterviewerContext.Provider
      value={{
        expertiseOptions,
        updateExpertiseOptions,
        expertiseFilter,
        setExpertiseFilter,
      }}
    >
      {children}
    </InterviewerContext.Provider>
  );
};

import styled from 'styled-components';
import { Modal, ModalProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { ModalButtonsAlignment } from './types';

export const ModalStyled = styled(
  (
    props: PropsWithChildren<
      ModalProps & {
        footerButtonsAlignment?: ModalButtonsAlignment;
      }
    >
  ) => <Modal {...props} />
)`
  button {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  }

  div.ant-modal-content div.ant-modal-header div.ant-modal-title {
    font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
  }

  div.ant-modal-content {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  }

  div.ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: ${({ footerButtonsAlignment }) =>
      footerButtonsAlignment === ModalButtonsAlignment.SPACE_BETWEEN
        ? 'space-between !important'
        : 'flex-end !important'};
    gap: 14px !important;
  }
  .ant-modal-footer div {
    display: contents;
  }

  .ant-btn {
    min-width: ${({ theme }) => theme.buttonSize.width};
    height: ${({ theme }) => theme.buttonSize.height};
    margin-right: 0;
    margin-left: 10px;
  }
  .ant-modal-title {
    color: white;
  }
  .ant-modal-header {
    color: white;
    background-color: ${({ theme }) => theme.colors.modalHeaderBackground};
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius}
      ${({ theme }) => theme.shape.generalBorderRadius} 0px 0px;
  }
  .ant-modal-close {
    color: white;
  }
  .ant-btn-default {
    color: ${({ theme }) => theme.colors.blue};
    border-color: ${({ theme }) => theme.colors.blue} !important;
  }
`;

import React, { FC, memo, useCallback } from 'react';
import Form from 'components/Form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select';
import { Input } from 'components/Input';
import PhoneInput from 'components/PhoneInput';
import DatePicker from 'components/DatePicker';
import Upload from 'components/Upload';
import Checkbox from 'components/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { LinkOutlined } from '@ant-design/icons';
import Editor from 'components/Editor';
import Switch from 'components/Switch';
import TagCollection from 'components/TagCollection';
import {
  FormItemConfig,
  FormItemType,
  AutoCompleteInterface,
} from '../helpers';
import { FormWrapper, FormSection, TagCollectionWrapper } from './styles';
import { MAX_ROWS, MIN_ROWS, MAX_LENGTH } from './helpers';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Autocomplete from '../../Autocomplete';
import { OptionType } from '../../Timetracker/ActivityModal/helpers';

interface Props {
  formItemConfig: FormItemConfig;
}

const FormInput: FC<Props> = ({ formItemConfig }: Props): JSX.Element => {
  const { t } = useTranslation();
  const filterOptionHandler = useCallback(
    (inputText, optionText) =>
      optionText?.children.toLowerCase().indexOf(inputText.toLowerCase()) >= 0,
    []
  );

  const getFilterName = (inputValue: string, option: OptionType) => {
    return (
      inputValue.length >= 1 &&
      option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    );
  };

  const filterSortHandler = useCallback(
    (optionA, optionB) =>
      optionA.children
        .toLowerCase()
        .localeCompare(optionB.children.toLowerCase()),
    []
  );

  const getInput = useCallback(
    (input: FormItemConfig): JSX.Element => {
      let tags = input.tagCollectionConfig?.items || [];
      let inputElement: JSX.Element = <></>;
      if (!input) {
        return inputElement;
      }

      if (input.type === FormItemType.section) {
        return <FormSection>{t(input.label)}</FormSection>;
      }

      switch (input.type) {
        case FormItemType.editor:
          inputElement = (
            <Editor
              maxLength={input.maxLength || MAX_LENGTH}
              label={input.label}
              rules={input.rules}
              editorState={input.editorState}
              onEditorStateChange={input.onEditorStateChange}
            />
          );
          break;
        case FormItemType.select:
          inputElement = (
            <Select
              showSearch
              mode={input.mode}
              optionFilterProp="children"
              key={`form-key-${input.name}`}
              placeholder={t(input.placeholder || '')}
              width="-webkit-fill-available"
              margin="0"
              disabled={input.disabled}
              filterOption={filterOptionHandler}
              filterSort={filterSortHandler}
              onChange={input.onChangeHandler || undefined}
              onSearch={input.onSearch}
              allowClear={input.allowClear}
            >
              {input.options?.map((option) => (
                <Select.Option
                  key={`option-key-${option.value}`}
                  value={option.value}
                >
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          );
          break;
        case FormItemType.textArea:
          inputElement = (
            <TextArea
              key={`form-key-${input.name}`}
              name={input.name}
              showCount
              placeholder={t(input.placeholder || '')}
              disabled={input.disabled}
              maxLength={input.max}
              autoSize={{
                minRows: MIN_ROWS,
                maxRows: MAX_ROWS,
              }}
              onChange={
                input.onChangeHandler
                  ? (event) => {
                      if (input.onChangeHandler) {
                        input.onChangeHandler(event.target.value);
                      }
                    }
                  : undefined
              }
              onKeyDown={input.onKeyDown}
              onKeyUp={(event: any) => {
                if (input.trimStart && input.form) {
                  const inputValue = event.target.value;
                  input.form.setFieldValue(
                    input.name,
                    inputValue ? inputValue.trimStart() : ''
                  );
                }
              }}
            />
          );
          break;
        case FormItemType.date:
          inputElement = (
            <DatePicker
              key={`form-key-${input.name}`}
              placeholder={t(input.placeholder || '')}
              disabled={input.disabled}
              format={(value) =>
                `${moment(value).format(input.dateConfig?.dateFormat)}`
              }
              onChange={input.onDateChangeHandler}
              defaultPickerValue={input.dateConfig?.defaultPickerValue}
              disabledDate={input.dateConfig?.disabledDate}
            />
          );
          break;
        case FormItemType.phone:
          inputElement = <PhoneInput disabled={input.disabled} />;
          break;
        case FormItemType.checkbox:
          inputElement = (
            <Checkbox
              disabled={input.disabled}
              onChange={input.onCheckboxChangeHandler || undefined}
            >
              {t(input.label)}
            </Checkbox>
          );
          break;
        case FormItemType.autocomplete:
          inputElement = (
            <Autocomplete
              showSearch
              disabled={input.disabled}
              options={input.options?.map((el) => ({
                value: el.name,
                key: el.value,
              }))}
              onChange={(value: any, a: AutoCompleteInterface) =>
                input.onChangeHandler
                  ? input.onChangeHandler({ data: value, value: a })
                  : undefined
              }
              placeholder={t(input.placeholder || '')}
              notFoundContent={
                <>
                  <LinkOutlined /> {t('humanCapital.skills.noData')}
                </>
              }
              filterOption={getFilterName}
            />
          );
          break;
        case FormItemType.upload:
          inputElement = (
            <Upload
              {...input.uploadConfig}
              key={`form-key-${input.name}`}
              width="220px"
              label={t(input.label)}
              disabled={input.disabled}
            />
          );
          break;

        case FormItemType.switch:
          inputElement = (
            <Switch
              disabled={input.disabled}
              key={`form-key-${input.name}`}
              defaultChecked={input.switchConfig?.defaultChecked}
              checkedChildren={t(input.switchConfig?.checkedChildren || '')}
              unCheckedChildren={t(input.switchConfig?.unCheckedChildren || '')}
              onChange={input.switchConfig?.onChange || input.onChangeHandler}
              checked={input.switchConfig?.checked}
            />
          );
          break;

        case FormItemType.tagCollection:
          inputElement = (
            <TagCollectionWrapper>
              <TagCollection
                tagList={input.tagCollectionConfig?.items}
                popoverTitle={input.tagCollectionConfig?.popoverTitle}
                addButtonText={input.tagCollectionConfig?.addButtonText}
                onChange={(props) => {
                  tags = props.tags;
                  input.form?.setFieldsValue({ [input.name]: tags });
                  input.tagCollectionConfig?.onChange?.(props);
                  input.tagCollectionConfig?.form.validateFields([input.name]);
                }}
                options={input.tagCollectionConfig?.options}
                isEditable={input.tagCollectionConfig?.isEditable}
                form={input.tagCollectionConfig?.form}
                fieldName={input.name}
              />
            </TagCollectionWrapper>
          );
          break;

        default:
          inputElement = (
            <Input
              key={`form-key-${input.name}`}
              name={input.name}
              min={input.min}
              max={input.max}
              type={input.type}
              placeholder={t(input.placeholder || '')}
              disabled={input.disabled}
              onBlur={input.onBlur}
              onChange={
                input.onChangeHandler
                  ? (event) => {
                      if (input.onChangeHandler) {
                        input.onChangeHandler(event.target.value);
                      }
                    }
                  : undefined
              }
              onKeyDown={input.onKeyDown}
              onKeyUp={(event: any) => {
                if (input.trimStart && input.form) {
                  const inputValue = event.target.value;
                  input.form.setFieldValue(
                    input.name,
                    inputValue ? inputValue.trimStart() : ''
                  );
                }
              }}
            />
          );
          break;
      }

      const getRules = () => {
        const modifiedRules = input.rules;
        if (input.type !== FormItemType.tagCollection) return modifiedRules;

        modifiedRules?.forEach((rule) => {
          if (rule.required) {
            modifiedRules?.push({
              validator: (...args: [any, string]) => {
                const [, value] = args;
                if (tags.length === 0 && !value) {
                  return Promise.reject(
                    t(
                      modifiedRules?.find((_rule) => _rule.required)?.message ||
                        ''
                    )
                  );
                }
                return Promise.resolve();
              },
            });
          }
        });

        return modifiedRules?.filter((rule) => !rule.required);
      };

      const rules = getRules();

      return (
        <FormWrapper>
          {input.type === FormItemType.editor ? (
            <>{inputElement}</>
          ) : (
            <Form.Item
              label={
                input.type === FormItemType.checkbox ? ' ' : t(input.label)
              }
              name={input.name}
              rules={rules}
              hidden={input.hidden}
              valuePropName={input.checked}
            >
              {inputElement}
            </Form.Item>
          )}
        </FormWrapper>
      );
    },
    [t, filterOptionHandler, filterSortHandler]
  );

  return getInput(formItemConfig);
};

export default memo(FormInput);

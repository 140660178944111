import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from 'components/Button';
import { Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { DivPagination } from 'components/Pagination/styles';
import Pagination from 'components/Pagination';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import { RouteComponentProps, useHistory } from 'react-router';
import { ACTIVE, TableQuery } from './helpers';
import {
  DatePickerStyled,
  DivFilter,
  InputStyled,
  RadioButtonGroupStyled,
  SearchOutlinedStyled,
} from './styles';

function getQueryParams<Columns extends Record<string, any>>(props: {
  name: string;
}): TableQuery<Columns> {
  const { name } = props;
  const query = new URLSearchParams(window.location.search);
  return JSON.parse(query.get(name) || '{}');
}

function setFullConfig<Columns extends Record<string, any>>(props: {
  name: string;
  newConfig: TableQuery<Columns>;
  history: RouteComponentProps['history'];
}) {
  const { name, newConfig, history } = props;
  const query = new URLSearchParams(window.location.search);
  const oldConfig = query.get(name);
  query.set(name, JSON.stringify(newConfig));
  if (query.get(name) === oldConfig) return;
  history.push({ search: query.toString() });
}

function resetFilter<Columns extends Record<string, any>>(props: {
  clearFilters: () => void;
  dataIndex: keyof Columns;
  name: string;
  history: RouteComponentProps['history'];
}) {
  const { clearFilters, dataIndex, name, history } = props;
  const config = getQueryParams({ name });

  clearFilters();

  setFullConfig({
    history,
    name,
    newConfig: {
      pagination: { ...config.pagination, page: 1 },
      filter: { ...config.filter, [dataIndex]: undefined },
    },
  });
}

function handleSearch<
  Columns extends Record<string | number | symbol, any>
>(props: {
  selectedKey: string;
  confirm: (param?: any) => void;
  dataIndex: keyof Columns;
  name: string;
  history: RouteComponentProps['history'];
}) {
  const { confirm, dataIndex, name, history, selectedKey } = props;
  const config = getQueryParams({ name });

  setFullConfig({
    history,
    name,
    newConfig: {
      pagination: { ...config.pagination, page: 1 },
      filter: { ...config.filter, [dataIndex]: selectedKey || undefined },
    },
  });

  confirm();
}

function getColumnSearchProps<Columns extends Record<string, any>>(props: {
  dataIndex: keyof Columns;
  t: (key: string) => string;
  placeholder?: string;
  name: string;
  history: RouteComponentProps['history'];
}): ColumnType<Columns> {
  const { dataIndex, placeholder, t, name, history } = props;
  const query = new URLSearchParams(history.location.search);
  const config = JSON.parse(query.get(name) || '{}');
  const defaultValue = config.filter?.[dataIndex] || '';

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const selectedKey = selectedKeys[0]
        ? selectedKeys[0].toString()
        : defaultValue;
      const value = selectedKeys[0]?.toString();
      return (
        <DivFilter>
          <InputStyled
            id={dataIndex.toString()}
            placeholder={placeholder}
            value={value}
            defaultValue={selectedKey}
            onChange={(event) => {
              setSelectedKeys(event.target.value ? [event.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              handleSearch({
                history,
                selectedKey: selectedKeys[0]?.toString(),
                confirm,
                dataIndex,
                name,
              });
              confirm();
            }}
          />
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  history,
                  selectedKey: selectedKeys[0]?.toString(),
                  confirm,
                  dataIndex,
                  name,
                });
                confirm();
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({ clearFilters, dataIndex, name, history });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? ACTIVE : ''} />
    ),
    onFilter: (value: string | number | boolean, record: Columns): boolean => {
      if (typeof value === 'string') {
        const result = record[dataIndex];
        return !!result;
      }
      return false;
    },
    render: (text: string) => {
      return t(text);
    },
  };
}

function getColumnStatusSearchProps<
  Columns extends Record<string, any>
>(props: {
  dataIndex: string;
  t: (key: string) => string;
  name: string;
  history: RouteComponentProps['history'];
}): ColumnType<Columns> {
  const { dataIndex, name, t, history } = props;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const query = new URLSearchParams(history.location.search);
      const config = JSON.parse(query.get(name) || '{}');
      const defaultValue = config.filter?.[dataIndex] || '';
      const selectedKey = selectedKeys[0]
        ? selectedKeys[0].toString()
        : defaultValue;
      return (
        <DivFilter>
          <RadioButtonGroupStyled>
            <Radio.Group
              onChange={(event) => {
                setSelectedKeys([event.target.value]);
                confirm({ closeDropdown: false });
              }}
              value={selectedKey}
              buttonStyle="solid"
            >
              <Radio value="true">{t(`common.active`)}</Radio>
              <Radio value="false">{t(`common.inactive`)}</Radio>
            </Radio.Group>
          </RadioButtonGroupStyled>
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  history,
                  selectedKey,
                  confirm,
                  dataIndex,
                  name,
                });
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({ clearFilters, dataIndex, name, history });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? 'active' : ''} />
    ),
  };
}

function getColumnDateSearchProps<Columns extends Record<string, any>>(props: {
  dataIndex: string;
  placeholder: string;
  t: (key: string) => string;
  name: string;
  history: RouteComponentProps['history'];
}): ColumnType<Columns> {
  const { dataIndex, name, placeholder, t, history } = props;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      const query = new URLSearchParams(history.location.search);
      const config = JSON.parse(query.get(name) || '{}');
      const defaultValue = config.filter?.[dataIndex] || '';
      const selectedKey = selectedKeys[0]
        ? selectedKeys[0].toString()
        : defaultValue;
      return (
        <DivFilter>
          <DatePickerStyled>
            <DatePicker
              placeholder={placeholder}
              onChange={(_, dateString: string) => {
                setSelectedKeys([dateString]);
                confirm({ closeDropdown: false });
              }}
              value={selectedKey ? moment(selectedKey) : null}
              allowClear
            />
          </DatePickerStyled>
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  history,
                  selectedKey,
                  confirm,
                  dataIndex,
                  name,
                });
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({
                    history,
                    clearFilters,
                    dataIndex,
                    name,
                  });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? ACTIVE : ''} />
    ),
  };
}

export function useActionTable<Columns extends Record<string, any>>(props: {
  name: string;
}) {
  const { name } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [config, setConfiguration] = useState(
    getQueryParams<Columns>({ name })
  );
  const [totalResults, setTotalResults] = useState<number>(0);
  const DEFAULT_PAGINATION = useRef({ page: 1, pageSize: 10 });
  const pagination = useMemo(
    () => ({
      ...DEFAULT_PAGINATION.current,
      ...config.pagination,
    }),
    [DEFAULT_PAGINATION, config.pagination]
  );

  const getTableRequestBody = useCallback(
    (_props: { sortColumn: string }): DTO.GetPaginatedList<Columns> => {
      const { sortColumn } = _props;

      return {
        page: pagination.page,
        pageSize: pagination.pageSize,
        sortColumn,
        sortDirection: 'asc',
        filter: config.filter,
      };
    },
    [config.filter, pagination.page, pagination.pageSize]
  );

  const generateInputFilter = useCallback(
    (_props: { dataIndex: keyof Columns; placeholder?: string }) => {
      const { dataIndex, placeholder } = _props;

      return getColumnSearchProps<Columns>({
        history,
        dataIndex,
        placeholder,
        t,
        name,
      });
    },
    [history, name, t]
  );

  const generateBooleanFilter = useCallback(
    (_props: { dataIndex: string }) => {
      const { dataIndex } = _props;

      return getColumnStatusSearchProps<Columns>({
        history,
        dataIndex,
        t,
        name,
      });
    },
    [history, name, t]
  );

  const generateDateFilter = useCallback(
    (_props: { dateIndex: string }) => {
      const { dateIndex } = _props;

      return getColumnDateSearchProps<Columns>({
        history,
        dataIndex: dateIndex,
        placeholder: t('common.selectDate'),
        t,
        name,
      });
    },
    [history, name, t]
  );

  const Paginator = useCallback(
    (_props: { name: string }) => {
      const { name: _name } = _props;

      const onChange = (page: number, pageSize: number) => {
        setFullConfig({
          history,
          name,
          newConfig: {
            ...config,
            pagination: { ...config.pagination, page, pageSize },
          },
        });
      };

      const onShowSizeChange = (current: number, size: number) => {
        setFullConfig({
          history,
          name,
          newConfig: {
            ...config,
            pagination: { ...config.pagination, page: current, pageSize: size },
          },
        });
      };

      return (
        <DivPagination>
          <Pagination
            total={totalResults}
            current={pagination.page}
            pageSize={pagination.pageSize}
            onChange={onChange}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger
            showTotal={(_total, range) =>
              `${range[0]}-${range[1]} ${t(
                'timetracker.timesheet.ofTotalHours'
              )} ${_total} ${_name}`
            }
          />
        </DivPagination>
      );
    },
    [
      config,
      history,
      name,
      pagination.page,
      pagination.pageSize,
      t,
      totalResults,
    ]
  );

  const setConfig = useCallback(
    (_props: { newConfig: TableQuery<Columns> }) => {
      const { newConfig } = _props;
      setFullConfig({
        history,
        name,
        newConfig,
      });
    },
    [history, name]
  );

  const setPagination = useCallback(
    (_props: { newPagination: typeof config.pagination }) => {
      const { newPagination } = _props;
      setConfig({
        newConfig: {
          ...config,
          pagination: newPagination,
        },
      });
    },
    [config, setConfig]
  );

  const setFilter = useCallback(
    (_props: { newFilter: typeof config.filter }) => {
      const { newFilter } = _props;
      setConfig({
        newConfig: {
          ...config,
          filter: newFilter,
        },
      });
    },
    [config, setConfig]
  );

  useEffect(() => {
    setConfiguration(getQueryParams({ name }));
  }, [name, history.location.search, history]);

  return {
    generateInputFilter,
    generateBooleanFilter,
    generateDateFilter,
    getTableRequestBody,
    Paginator,
    config,
    setConfig,
    setTotalResults,
    totalResults,
    setPagination,
    setFilter,
    DEFAULT_PAGINATION: DEFAULT_PAGINATION.current,
  };
}

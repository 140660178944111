import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class ReportService {
  getReportTime = (startDate: string, endDate: string) => {
    return apiService.requestTimesheetReport({
      url: `${Endpoints.getReportTime}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };

  getReportWithActivities = (startDate: string, endDate: string) => {
    return apiService.requestTimesheetReport({
      url: `${Endpoints.getReportTimeWithActivities}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };

  getReportWorkedHours = (startDate: string, endDate: string) => {
    return apiService.requestTimesheetReport({
      url: `${Endpoints.getReportWorkedHours}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };

  getReportTimeOff = (startDate: string, endDate: string) => {
    return apiService.requestTimesheetReport({
      url: `${Endpoints.getReportTimeOff}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };

  getReportWithHoursType = (startDate: string, endDate: string) => {
    return apiService.requestTimesheetReport({
      url: `${Endpoints.getReportWithHoursType}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const reportService = new ReportService();

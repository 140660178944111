export const TIMETRACKER_PATH = {
  ROOT: '/timetracker',
  HOME: '/timetracker/home',
  ABSENCES: '/timetracker/timeOff',
  REVIEW_TIMESHEETS: '/timetracker/reviewTimesheets',
  REVIEW_ALL_TIMESHEETS: '/timetracker/reviewAllTimesheets',
  REVIEW_TIMESHEETS_BY_AREA: '/timetracker/reviewTimesheetsByArea',
  REVIEW_ALL_TIMESHEETS_HISTORY: '/timetracker/reviewTimesheetsByArea/history',
  REPORTS: '/timetracker/reports',
  IMPORT_REPORTS: '/timetracker/importReports',
  USERS: '/timetracker/users',
  WILDCARD: '/timetracker/*',
  MEMBERS_TIMESHEET: '/timetracker/members/members-timesheet/:userId',
  TIMESHEETS: '/timetracker/timesheets',
  TIMESHEETS_EDIT: '/timetracker/timesheets/edit/:timesheetId',
  TIMESHEETS_VIEW: '/timetracker/timesheets/view/:timesheetId',
  REVIEW_ABSENCES: '/timetracker/reviewTimeOff',
  MANAGE_TIMESHEET: '/timetracker/manageTimesheets',
  REVIEW_ALL_ABSENCES: '/timetracker/reviewAllTimeOff',
  TIMESHEETS_REVIEWER_EDIT: '/timetracker/manageTimesheets/edit/:timesheetId',
  TIMESHEETS_REVIEWER_VIEW: '/timetracker/manageTimesheets/view/:timesheetId',
  NOTIFICATIONS: '/timetracker/notifications',
  MANAGE_ABSENCES: '/timetracker/manageTimeOff',
  TIME_OFF_HISTORY: '/timetracker/reviewAllTimeOff/:timeOffId/history',
};

export const CONFIGURATIONS_PATH = {
  ASSIGNMENT_LIST: '/configurations/projects/assignments',
  BILLABLE_ROLES: '/configurations/customers/roles/:clientId',
  COMPANY_TAB: '/configurations/companies/:tab?',
  CATALOGUES: '/configurations/companies/catalogues',
  COMPANY_CATALOGUE_CONTRACTS:
    '/configurations/companies/catalogues/contract/:contractId',
  CONTRACTS: '/configurations/companies/catalogues/contracts',
  HOUR_TYPE: '/configurations/companies/catalogues/hourType',
  COMPANY: '/configurations/companies',
  COMPANY_EDIT: '/configurations/companies/:companyId',
  CUSTOMER_PROJECTS: '/configurations/customers/projects/:clientId',
  CUSTOMERS: '/configurations/customers',
  HOLIDAYS: '/configurations/holidays',
  PROFILE_TREE: '/configurations/profiles/profile_tree',
  PROFILES: '/configurations/profiles',
  PROJECT_APPROVERS:
    '/configurations/projects/:projectId/approvers/:approverModalMode?/:approverId?',
  PROJECT_DETAILS: '/configurations/projects/project-details',
  PROJECT_EXTERNAL_ID: '/configurations/projects/external-id',
  PROJECTS: '/configurations/projects',
  ROOT: '/configurations',
  SETTINGS: '/configurations/settings',
  USERS_PROFILES_AREAS: '/configurations/users/areas',
  USERS_PROFILES: '/configurations/users/profiles',
  USERS: '/configurations/users',
  WILDCARD: '/configurations/*',
};

export const HUMAN_MANAGEMENT_PATH = {
  ROOT: '/humanManagement',
  PEOPLE: '/humanManagement/people',
  PEOPLE_VIEW: '/humanManagement/people/view/:personId',
  PEOPLE_CREATE: '/humanManagement/people/create',
  PEOPLE_EDIT: '/humanManagement/people/edit/:personId',
  PHOTO: '/humanManagement/photo',
  BILLING: '/humanManagement/people/employmentData/billing/:id',
  WILDCARD: '/humanManagement/*',
};

export const PLACEMENT_PATH = {
  ROOT: '/placement',
  POSITIONS: '/placement/positions',
  ADD_POSITION: '/placement/positions/addPosition',
  VIEW: '/placement/positions/view/:positionId',
  EDIT: '/placement/positions/edit/:positionId',
  CANDIDATES: '/placement/candidate',
  CANDIDATE_FORM: '/placement/candidate/:mode',
  INTERVIEWERS: '/placement/interviewer/:mode?/:id?',
};

export const ADMINISTRATION_PATH = {
  ROOT: '/administration',
  COMPANIES_CREATE: '/administration/companies/create',
  COMPANIES_EDIT: '/administration/companies/edit/:companyId',
  COMPANIES_VIEW: '/administration/companies/view/:companyId',
  COMPANIES: '/administration/companies',
  COMPANY_GROUP: '/administration/companyGroups',
  COMPANY_SETTINGS: '/administration/companies/:id/:name/settings',
};

export const APP_PATH = {
  ROOT: '/',
  HOME: '/home',
  ERROR: '/error',
  LOGIN: '/login',
  NOUSER: '/nouser',
  POLICY: '/policy',
  TERMS: '/terms',
  MANAGE_ACCOUNT: '/home/manage-account',
  TIMETRACKER: TIMETRACKER_PATH.ROOT,
  CONFIGURATIONS: CONFIGURATIONS_PATH.ROOT,
  HUMAN_MANAGEMENT: HUMAN_MANAGEMENT_PATH.ROOT,
  POSITIONS: PLACEMENT_PATH.ROOT,
  ADMINISTRATION: ADMINISTRATION_PATH.ROOT,
  WILDCARD: '/*',
};

const MONDAY_INDEX = 0;
const INCLUDE_JAN_FIRST = 6;
export const MOMENT_ES_LOCALE_CONFIG = {
  week: { dow: MONDAY_INDEX, doy: INCLUDE_JAN_FIRST },
};

export const ACTUAL_ACCOUNT = 0;

export type State<Columns> = [
  filter: Columns,
  setFilter: React.Dispatch<React.SetStateAction<Columns>>
];

export interface PaginationType {
  page: number;
  pageSize: number;
  total: number;
}

export const ACTIVE = 'active';

export interface TableQuery<Filter extends Record<string, any>> {
  pagination?: {
    page?: number;
    pageSize?: number;
  };
  filter: Filter;
}

export interface UseActionTableResponse<Data> {
  data: Data[];
  pagination: PaginationType;
}

import { Endpoints } from 'shared/constants/endpoints.const';
import { apiService } from './api.service';

class EmploymentDataService {
  getById = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getEmploymentDataByPersonId}${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getLastContractDates = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getLastContractDates}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getNextAndPrevius = (
    employmentDataId: number,
    personId: number
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getNextAndPrevius}/${employmentDataId}/${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  fetchCategories = (): Promise<any> => {
    return apiService.request({
      url: Endpoints.getCategories,
      options: {
        method: 'GET',
      },
    });
  };

  fetchJobPositionsByCategoryId = (
    categoryId?: number | string
  ): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getJobPositionsByCategory}/${categoryId}`,
      options: {
        method: 'GET',
      },
    });
  };

  getByOwner = (personId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.getEmploymentDataByOwner}${personId}`,
      options: {
        method: 'GET',
      },
    });
  };

  addEmploymentData = (employmentData: DTO.AddEmploymentData): Promise<any> => {
    return apiService.request({
      url: Endpoints.addEmploymentData,
      options: {
        method: 'POST',
        body: JSON.stringify(employmentData),
      },
    });
  };

  updateEmploymentData = (employmentData: DTO.EmploymentData): Promise<any> => {
    return apiService.request({
      url: Endpoints.updateEmploymentData,
      options: {
        method: 'PUT',
        body: JSON.stringify(employmentData),
      },
    });
  };

  getEmploymentDataList = (
    personId: number,
    page: number,
    pageSize: number,
    searchTerm: string,
    filter: any
  ): Promise<DTO.GridResults<any>> => {
    return apiService.request({
      url: `${Endpoints.getEmploymentDataList}/${personId}`,
      options: {
        method: 'POST',
        body: JSON.stringify({
          valueId: '',
          page,
          pageSize,
          searchTerm,
          sortColumn: 'contractNumber',
          sortDirection: 'asc',
          total: 0,
          message: '',
          filter: filter || {},
        }),
      },
    });
  };

  addEmploymentDataList = (addData: any): Promise<any> => {
    const bodyData = {
      ...addData,
      workingHoursPerWeek: Number(addData.workingHoursPerWeek),
    };
    return apiService.request({
      url: `${Endpoints.addEmploymentDataList}`,
      options: {
        method: 'POST',
        body: JSON.stringify(bodyData),
      },
    });
  };

  editEmploymentDataList = (updateData: any): Promise<any> => {
    const bodyData = {
      ...updateData,
      workingHoursPerWeek: Number(updateData.workingHoursPerWeek),
    };
    return apiService.request({
      url: `${Endpoints.editEmploymentDataList}`,
      options: {
        method: 'PUT',
        body: JSON.stringify(bodyData),
      },
    });
  };

  updateEmploymentDataIsActive = (
    employmentDataId: number,
    isActive: boolean
  ): Promise<any> => {
    return apiService.request({
      url: `${
        isActive
          ? Endpoints.activeEmploymentData
          : Endpoints.inactiveEmploymentData
      }/${employmentDataId}`,
      options: {
        method: 'PATCH',
      },
    });
  };

  getEmploymentDataById = (
    employmentDataId: number
  ): Promise<DTO.ActionResult<DTO.EmploymentData>> => {
    return apiService.request({
      url: `${Endpoints.getEmploymentDataById}/${employmentDataId}`,
      options: {
        method: 'GET',
      },
    });
  };

  deleteEmploymentData = (employmentDataId: number): Promise<any> => {
    return apiService.request({
      url: `${Endpoints.deleteEmploymentData}/${employmentDataId}`,
      options: {
        method: 'DELETE',
      },
    });
  };

  getInvoices = (
    employmentDataId: string,
    body: DTO.getBillingRequest
  ): Promise<DTO.getBillingResponse> => {
    return apiService.request({
      url: `${Endpoints.getInvoices}/${employmentDataId}`,
      options: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
  };

  getWorkingHoursPerWeek = (
    personId: number,
    startDate: string,
    endDate: string
  ): Promise<DTO.ActionResult<number>> => {
    return apiService.request({
      url: `${Endpoints.getWorkingHoursPerWeek}${personId}/${startDate}/${endDate}`,
      options: {
        method: 'GET',
      },
    });
  };
}

export const employmentDataService = new EmploymentDataService();

import React from 'react';
import {
  ADMINISTRATION_PATH,
  CONFIGURATIONS_PATH,
} from 'features/Routes/helpers';
import flowhrLogo from 'shared/images/flowhr_logo.svg';
import { FIRST_ELEMENT } from 'shared/constants/common.const';
import { IdcardOutlined } from '@ant-design/icons';
import { LayoutConfig, MenuItem } from 'features/Layouts/helpers';
import { Permissions } from '../GuardedRoute/helpers';

export const CONFIGURATION_LAYOUT: LayoutConfig = {
  title: 'timetracker.title',
  logo: flowhrLogo,
  menuItems: [
    {
      key: 1,
      name: 'administrations.companies',
      icon: <IdcardOutlined />,
      path: ADMINISTRATION_PATH.COMPANIES,
      nestedPaths: [
        {
          name: 'timetracker.companies.create',
          path: ADMINISTRATION_PATH.COMPANIES_CREATE,
        },
        {
          name: 'timetracker.companies.edit',
          path: ADMINISTRATION_PATH.COMPANIES_EDIT,
        },
        {
          name: 'timetracker.companies.view',
          path: ADMINISTRATION_PATH.COMPANIES_VIEW,
        },
        {
          name: 'administration.companies.modulesSettings',
          path: ADMINISTRATION_PATH.COMPANY_SETTINGS,
        },
      ],
    },
    {
      key: 2,
      name: 'administrations.companyGroups',
      icon: <IdcardOutlined />,
      path: ADMINISTRATION_PATH.COMPANY_GROUP,
    },
  ],
};

export const getInitialRoute = (permissions: Permissions[]): string => {
  const conf: Permissions[] = permissions.filter(
    (permission) => permission.sectionPath === CONFIGURATIONS_PATH.ROOT
  );
  const confgOrder: string = conf.reduce((lowest, current) => {
    return current.order < lowest.order ? current : lowest;
  }, conf[FIRST_ELEMENT]).optionPath;

  return confgOrder;
};

export const getAllLayout = (permissions: Permissions[]): LayoutConfig => {
  const layout: LayoutConfig = {
    title: 'timetracker.title',
    logo: flowhrLogo,
    menuItems: [],
  };
  const menuFiltered = CONFIGURATION_LAYOUT.menuItems.filter(
    (menu: MenuItem) => {
      return permissions.some((permission: Permissions) => {
        return permission.optionPath === menu.path;
      });
    }
  );

  layout.menuItems = menuFiltered;
  return layout;
};

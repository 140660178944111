import styled from 'styled-components';
import { Tabs } from 'antd';

export const TabsStyled = styled(Tabs)`
  background: white !important;
  min-height: 75vh;
  -webkit-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  .ant-tabs-tab.ant-tabs-tab-active {
    background: ${({ theme }) => theme.colors.modalHeaderBackground} !important;
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius} 0 0 0 !important;
    padding: 1.2vh !important;
  }
  .ant-tabs-tab {
    background: transparent !important;
    padding: 1vh !important;
  }
  .ant-tabs-nav {
    border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
    background: #ecf1ff !important;
    width: 20vh;
  }
  .ant-tabs-tab-btn {
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
`;

export const TabPaneStyled = styled(Tabs.TabPane)`
  background: #fff;
`;

import { FormModes } from 'shared/constants/common.const';
import {
  AutoCompleteInterface,
  FormItemConfig,
  FormItemType,
  FormSelectMode,
  Options,
  sortOptions,
} from 'components/Form/helpers';
import { EditorState } from 'react-draft-wysiwyg';
import { removeSpacesFromFormField } from 'shared/utils/common.utils';
import { FormInstance } from 'components/Form';

export const POSITION_TAB = '1';
export const SKILLS_KEY_TAB = '2';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const SPAN_COL = 8;
const MAX_LENGTH = 2500;
const MIN_LENGTH = 2;

export const getCountryOptions = (
  countries: DTO.Country[],
  t: Function
): Options[] => {
  const countryOptions: Options[] = countries.map((country) => {
    return { value: country.countryId, name: t(country.name) } as Options;
  });
  return sortOptions(countryOptions);
};

export const getPositionsTypes = (
  positions: DTO.PositionResponse<DTO.PositionType>,
  t: Function
): Options[] => {
  const positionTypesOptions: Options[] = positions.results.map((position) => {
    return {
      value: position.id,
      name: t(position.positionTypeName),
    } as Options;
  });
  return sortOptions(positionTypesOptions);
};

export const getPositionSeniorityTypes = (
  positions: DTO.PositionResponse<DTO.PositionSeniority>,
  t: Function
): Options[] => {
  const positionSeniorityOptions: Options[] = positions.results.map(
    (position) => {
      return {
        value: position.id,
        name: t(position.name),
      } as Options;
    }
  );
  return sortOptions(positionSeniorityOptions);
};

export const getPositionsRolesTypes = (
  roles: DTO.PositionResponse<DTO.PositionRoles>,
  t: Function
): Options[] => {
  const positionsRoles: Options[] = roles.results.map((role) => {
    return {
      value: `${role.id}-${role.name}`,
      name: t(role.name),
    } as Options;
  });
  return sortOptions(positionsRoles);
};

export const getPositionClientsTypes = (
  positions: DTO.PositionResponse<DTO.PositionClient>,
  t: Function
): Options[] => {
  const positionClient: Options[] = positions.results.map((client) => {
    return {
      value: `${client.id}-${client.name}`,
      name: t(client.name),
    } as Options;
  });
  return sortOptions(positionClient);
};

export const getPositionProjectTypes = (
  positions: DTO.PositionResponse<DTO.PositionProject>,
  t: Function
): Options[] => {
  const positionProjectOptions: Options[] = positions.results.map(
    (position) => {
      return {
        value: `${position.id}-${position.name}`,
        name: t(position.name),
      } as Options;
    }
  );
  return sortOptions(positionProjectOptions);
};

export const formatPosition = (position: DTO.PositionDraft<EditorState>) => {
  const newPosition: DTO.NewPositionDraft = {
    positionName: position.positionName,
    jobDescription: '',
    mainResponsabilities: '',
    seniorityId: position.seniority,
    clientId: 0,
    roleId: 0,
    projectId: 0,
    positionTypeId: position.positionType,
    saleId: position.saleId,
    requestBy: position.requestBy,
    comments: null,
    role: {
      id: 0,
      name: '',
    },
    project: {
      id: 0,
      name: '',
      clientId: 0,
    },
    positionAllocations: position.allocation.map((allocation) => ({
      countryAllocationId: allocation,
      isActive: true,
    })),
    positionStartDate: position.startDate,
    openPositionSkills: [],
  };
  return newPosition;
};

export const getPositionDataFields = (
  form: FormInstance,
  salesChannels: Options[],
  countries: Options[],
  positionTypes: Options[],
  positionSeniority: Options[],
  positionRoles: Options[],
  positionClients: Options[],
  positionProjects: Options[],
  formMode: FormModes,
  disableStartDateHandler: (current: any) => boolean,
  jobPosition: EditorState | undefined,
  onChangeJobPosition: (value: EditorState) => void,
  mainResponsibilities: EditorState | undefined,
  onChangeMainResponsibilities: (value: EditorState) => void,
  onChangeClient: ({
    data,
    value,
  }: {
    data?: string;
    value?: AutoCompleteInterface;
  }) => void,
  onChangeRole: ({
    data,
    value,
  }: {
    data?: string;
    value: AutoCompleteInterface;
  }) => void,
  onChangeProject: ({
    data,
    value,
  }: {
    data?: string;
    value?: AutoCompleteInterface;
  }) => void,
  t: Function
): FormItemConfig[] => {
  return [
    {
      name: 'positionName',
      label: 'positions.positionName',
      disabled: formMode === FormModes.edit,
      type: 'text',
      placeholder: 'positions.positionName',
      onChangeHandler() {
        removeSpacesFromFormField(form, 'positionName', true);
      },
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 50, message: t('common.min2Max50FieldRequired') },
        { min: 2, message: t('common.min2Max50FieldRequired') },
      ],
    },
    {
      name: 'seniority',
      label: 'positions.seniority',
      type: 'select',
      options: positionSeniority,
      placeholder: 'positions.seniority',
      allowClear: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'role',
      label: 'positions.roleName',
      type: 'autocomplete',
      options: positionRoles,
      disabled: formMode === FormModes.edit,
      placeholder: 'positions.roleName',
      onChangeHandler: (props) => {
        removeSpacesFromFormField(form, 'role', true);
        onChangeRole(props);
      },
      allowClear: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 90, message: t('common.min2Max90FieldRequired') },
        { min: 2, message: t('common.min2Max90FieldRequired') },
      ],
    },
    {
      name: 'client',
      label: 'positions.client',
      type: 'autocomplete',
      disabled: formMode === FormModes.edit,
      options: positionClients,
      placeholder: 'positions.client',
      allowClear: true,
      onChangeHandler: (props) => {
        removeSpacesFromFormField(form, 'client', true);
        onChangeClient(props);
      },
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 90, message: t('common.min2Max90FieldRequired') },
        { min: 2, message: t('common.min2Max90FieldRequired') },
      ],
    },
    {
      name: 'projectName',
      label: 'positions.projectName',
      type: 'autocomplete',
      options: positionProjects,
      allowClear: true,
      onChangeHandler: (props) => {
        removeSpacesFromFormField(form, 'projectName', true);
        onChangeProject(props);
      },
      placeholder: 'positions.projectName',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        { max: 90, message: t('common.min2Max90FieldRequired') },
        { min: 2, message: t('common.min2Max90FieldRequired') },
      ],
    },
    {
      name: 'startDate',
      label: 'positions.startDate',
      disabled: formMode === FormModes.edit,
      type: 'date',
      dateConfig: {
        disabledDate: disableStartDateHandler,
        dateFormat: DATE_FORMAT,
        defaultPickerValue: undefined,
      },
      placeholder: 'positions.startDate',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'positionType',
      label: 'positions.positionType',
      type: 'select',
      options: positionTypes,
      allowClear: true,
      placeholder: 'positions.positionType',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'allocation',
      label: 'positions.candidate.location',
      type: 'select',
      options: countries,
      mode: FormSelectMode.multiple,
      placeholder: 'positions.candidate.location',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'saleId',
      label: 'positions.salesChannel',
      type: 'select',
      options: salesChannels,
      placeholder: 'positions.salesChannel',
      allowClear: true,
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
      ],
    },
    {
      name: 'requestBy',
      label: 'positions.requestedBy',
      type: 'text',
      placeholder: 'positions.requestedBy',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        {
          max: 250,
          message: t('common.fieldMustNotExceedXCharacters', { x: 250 }),
        },
        {
          min: 2,
          message: t('common.fieldMustHaveAtLeastXCharacters', { x: 2 }),
        },
      ],
    },
    {
      name: 'jobDescription',
      label: 'positions.jobDescription',
      editorState: jobPosition,
      onEditorStateChange: onChangeJobPosition,
      type: FormItemType.editor,
      isSection: true,
      maxLength: MAX_LENGTH,
      placeholder: 'positions.jobDescription',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        {
          max: MAX_LENGTH,
          message: t('common.fieldMustNotExceedXCharacters', {
            x: MAX_LENGTH,
          }),
        },
        {
          min: MIN_LENGTH,
          message: t('common.fieldMustHaveAtLeastXCharacters', {
            x: MIN_LENGTH,
          }),
        },
      ],
    },
    {
      name: 'mainResponsibilities',
      label: 'positions.mainResponsibilities',
      editorState: mainResponsibilities,
      onEditorStateChange: onChangeMainResponsibilities,
      type: FormItemType.editor,
      maxLength: MAX_LENGTH,
      isSection: true,
      placeholder: 'positions.mainResponsibilities',
      rules: [
        {
          required: true,
          message: t('common.fieldIsRequired'),
        },
        {
          max: MAX_LENGTH,
          message: t('common.fieldMustNotExceedXCharacters', {
            x: MAX_LENGTH,
          }),
        },
        {
          min: MIN_LENGTH,
          message: t('common.fieldMustHaveAtLeastXCharacters', {
            x: MIN_LENGTH,
          }),
        },
      ],
    },
  ];
};

export const requiredFields = [
  'emailAdress',
  'identificationTypeId',
  'identification',
  'name',
  'firstLastName',
  'birthdate',
  'phoneNumber',
  'countryId',
  'stateId',
  'cityId',
  'address',
  'zipCode',
  'genderId',
];

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormModes } from 'shared/constants/common.const';
import { Spin } from 'antd';
import { FormInstance } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'react-draft-wysiwyg';
import {
  actionsConfigConstructor,
  tabFormConfigConstructor,
} from 'components/Timetracker/Form/TabForm/helpers';
import { positionsService } from 'shared/services/positions.service';
import {
  getRequiredFields,
  validateStartDateVersusTodayAndEndDate,
} from 'shared/utils/common.utils';
import TabForm from 'components/Timetracker/Form/TabForm';
import { clientService } from 'shared/services';
import { getErrorInfo } from 'shared/utils/error.utils';
import { AutoCompleteInterface, Options } from 'components/Form/helpers';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  ContentState,
  convertToRaw,
  EditorState as EditorStateDraft,
} from 'draft-js';
import moment from 'moment';
import { salesService } from 'shared/services/sales.service';
import { TabFormPositionWrapper } from './styles';
import {
  formatPosition,
  getCountryOptions,
  getPositionClientsTypes,
  getPositionDataFields,
  getPositionProjectTypes,
  getPositionSeniorityTypes,
  getPositionsRolesTypes,
  getPositionsTypes,
  SPAN_COL,
} from './helper';
import AddPositionsContext from '../addPositionsContext';

interface NewPositionProps {
  positionForm: FormInstance;
  formMode: FormModes;
  onPositionDataFinish: (values: DTO.NewPositionDraft) => Promise<void>;
  handleEditPosition: (e: any) => Promise<any>;
  pathToRedirect: string;
  setIsTabDisabled: (value: boolean) => void;
  pathToRedirectOnCancel: string;
}

const NewPosition = (props: NewPositionProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobPosition, setJobPosition] = useState<EditorState | undefined>();
  const [mainResponsibilities, setMainResponsibilities] = useState<
    EditorState | undefined
  >();
  const [roleSelected, setRoleSelected] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: '',
  });
  const [clientSelected, setClientSelected] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: '',
  });
  const [projectSelected, setProjectSelected] = useState<{
    id: number;
    name: string;
    clientId: number;
  }>({
    id: 0,
    name: '',
    clientId: 0,
  });
  const [countries, setCountries] = useState<Options[]>([]);
  const [positionsType, setPositionsType] = useState<Options[]>([]);
  const [positionRoles, setPositionRoles] = useState<Options[]>([]);
  const [positionClients, setPositionClients] = useState<Options[]>([]);
  const [positionProjects, setPositionProjects] = useState<Options[]>([]);
  const [positionSeniority, setPositionSeniority] = useState<Options[]>([]);
  const [salesChannels, setSalesChannels] = useState<Options[]>([]);
  const { currentPosition, hasChanged, setHasChanged } =
    useContext(AddPositionsContext);
  const {
    pathToRedirect,
    handleEditPosition,
    positionForm,
    formMode,
    onPositionDataFinish,
    setIsTabDisabled,
    pathToRedirectOnCancel,
  } = props;

  const onFieldsChange = useCallback(() => {
    setHasChanged(true);
  }, [setHasChanged]);

  const getCountries = useCallback(async () => {
    try {
      const response = await clientService.fetchCountries();
      setCountries(getCountryOptions(response, t));
    } catch (error) {
      getErrorInfo(error as Promise<unknown>, t);
    }
  }, [t, setCountries]);

  const getPositionsType = useCallback(async () => {
    try {
      const response = await positionsService.getPositionTypes();
      setPositionsType(getPositionsTypes(response, t));
    } catch (error) {
      getErrorInfo(error as Promise<unknown>, t);
    }
  }, [t]);

  const getPositionRoles = useCallback(async () => {
    try {
      const response = await positionsService.getPlacementPositionRoles();
      setPositionRoles(getPositionsRolesTypes(response, t));
    } catch (error) {
      getErrorInfo(error as Promise<unknown>, t);
    }
  }, [t]);

  const getPositionClients = useCallback(async () => {
    try {
      const response = await positionsService.getPlacementPositionClientName();
      setPositionClients(getPositionClientsTypes(response, t));
    } catch (error) {
      getErrorInfo(error as Promise<unknown>, t);
    }
  }, [t]);

  const getPositionSeniority = useCallback(async () => {
    try {
      const response = await positionsService.getPositionSeniority();
      setPositionSeniority(getPositionSeniorityTypes(response, t));
    } catch (error) {
      getErrorInfo(error as Promise<unknown>, t);
    }
  }, [t]);

  const getPositionProject = useCallback(
    async ({
      data,
      value,
    }: {
      data?: string;
      value?: AutoCompleteInterface;
    }) => {
      if (value && Object.values(value).length !== 0) {
        const currentClient = positionClients.find(
          (el) => el.name === value.value
        );
        const clientId = (
          currentClient ? currentClient.value.toString().split('-')[0] || 0 : 0
        ).toString();
        try {
          setClientSelected({
            id: parseInt(clientId.toString(), 10),
            name: value.value,
          });
          const response = await positionsService.getPlacementPositionProject(
            undefined,
            data
          );
          setPositionProjects(getPositionProjectTypes(response, t));
        } catch (error) {
          setPositionProjects([]);
        }
      } else {
        setClientSelected({
          id: 0,
          name: data || '',
        });
        setPositionProjects([]);
      }
    },
    [positionClients, t]
  );

  const setInitialData = useCallback(async () => {
    if ([FormModes.viewing].includes(formMode)) return;
    const fetchCountries = getCountries();
    const fetchPositionsType = getPositionsType();
    const fetchPositionRoles = getPositionRoles();
    const fetchPositionClients = getPositionClients();
    const fetchSeniority = getPositionSeniority();
    await Promise.allSettled([
      fetchPositionsType,
      fetchCountries,
      fetchPositionRoles,
      fetchPositionClients,
      fetchSeniority,
    ]);

    setIsLoading(false);
  }, [
    formMode,
    getCountries,
    getPositionsType,
    getPositionRoles,
    getPositionClients,
    getPositionSeniority,
  ]);

  const setForm = useCallback(async () => {
    if (currentPosition) {
      positionForm.setFieldValue('positionName', currentPosition.positionName);
      positionForm.setFieldValue('seniority', currentPosition.seniorityId);
      positionForm.setFieldValue('saleId', currentPosition.saleId || undefined);
      positionForm.setFieldValue('requestBy', currentPosition.requestBy);
      const currentRole = positionRoles.find(
        (role) =>
          role.value.toString().split('-')[0] ===
          currentPosition.roleId.toString()
      );
      if (currentRole) {
        setRoleSelected({
          id: currentPosition.roleId,
          name: currentRole.name,
        });
        positionForm.setFieldValue('role', currentRole.name);
      }
      const response = await positionsService.getPlacementPositionProject(
        undefined,
        currentPosition.clientName
      );
      setPositionProjects(getPositionProjectTypes(response, t));
      positionForm.setFieldValue('client', currentPosition.clientName);
      positionForm.setFieldValue('projectName', currentPosition.projectName);
      setProjectSelected({
        id: currentPosition.projectId,
        clientId: currentPosition.clientId,
        name: currentPosition.projectName,
      });
      positionForm.setFieldValue(
        'positionType',
        currentPosition.positionTypeId
      );
      positionForm.setFieldValue(
        'startDate',
        moment(currentPosition.positionStartDate)
      );
      const jobState = EditorStateDraft.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(currentPosition.jobDescription).contentBlocks
        )
      );
      const responsibilitiesStates = EditorStateDraft.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(currentPosition.mainResponsabilities).contentBlocks
        )
      );
      positionForm.setFieldValue('jobDescription', jobState);
      positionForm.setFieldValue(
        'mainResponsibilities',
        responsibilitiesStates
      );
      setJobPosition(jobState);
      setMainResponsibilities(responsibilitiesStates);
      positionForm.setFieldValue(
        'allocation',
        countries
          .filter((country) =>
            currentPosition.positionAllocations.find(
              (el) => el.countryAllocationId === country.value
            )
          )
          .map((el) => el.value)
      );
    }
    setHasChanged(false);
  }, [
    countries,
    currentPosition,
    positionForm,
    positionRoles,
    setHasChanged,
    t,
  ]);

  const disableStartDateHandler = (current: any) => {
    const startDate = positionForm.getFieldValue('startDate');
    return !validateStartDateVersusTodayAndEndDate(current, startDate);
  };

  useEffect(() => {
    setForm();
  }, [setForm]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const onChangeJobPosition = (value: EditorState) => {
    positionForm.setFields([{ name: 'jobDescription', value, touched: true }]);
    setJobPosition(value);
  };

  const onChangeMainResponsibilities = (value: EditorState) => {
    positionForm.setFields([
      { name: 'mainResponsibilities', value, touched: true },
    ]);
    setMainResponsibilities(value);
  };

  const onChangeRole = useCallback(
    ({ data, value }: { data?: string; value?: AutoCompleteInterface }) => {
      const id = value && value.key ? parseInt(value.key.split('-')[0], 10) : 0;
      setRoleSelected({
        id,
        name: data || value?.value || '',
      });
    },
    []
  );

  const onChangeProject = useCallback(
    ({ data, value }: { data?: string; value?: AutoCompleteInterface }) => {
      const id = value && value.key ? parseInt(value.key.split('-')[0], 10) : 0;
      setProjectSelected({
        id,
        name: data || value?.value || '',
        clientId: 0,
      });
    },
    []
  );

  const getSalesChannel = useCallback(async () => {
    const response = await salesService.getSalesChannels();

    const salesChannelsOptions = response.results.map((el) => ({
      value: el.id,
      name: el.name,
    }));

    setSalesChannels(salesChannelsOptions);
  }, []);

  const positionDataFields = getPositionDataFields(
    positionForm,
    salesChannels,
    countries,
    positionsType,
    positionSeniority,
    positionRoles,
    positionClients,
    positionProjects,
    formMode,
    disableStartDateHandler,
    jobPosition,
    onChangeJobPosition,
    mainResponsibilities,
    onChangeMainResponsibilities,
    getPositionProject,
    onChangeRole,
    onChangeProject,
    t
  );

  const requiredFields = useMemo(() => {
    return getRequiredFields(positionDataFields);
  }, [positionDataFields]);

  const tabFormConfig = tabFormConfigConstructor(
    formMode,
    positionForm,
    positionDataFields,
    undefined,
    SPAN_COL
  );

  const onSubmitForm = async (values: DTO.PositionDraft<EditorState>) => {
    const positionData = {
      ...formatPosition(values),
      jobDescription: jobPosition
        ? draftToHtml(convertToRaw(jobPosition.getCurrentContent()))
        : '',
      mainResponsabilities: mainResponsibilities
        ? draftToHtml(convertToRaw(mainResponsibilities.getCurrentContent()))
        : '',
      roleId: roleSelected.id,
      role: roleSelected,
      project: projectSelected,
      projectId: projectSelected.id,
      clientId: clientSelected.id,
      client: clientSelected,
    };
    await onPositionDataFinish(positionData);
  };

  const actionsConfig = actionsConfigConstructor(
    onSubmitForm,
    handleEditPosition,
    pathToRedirect,
    pathToRedirectOnCancel
  );

  useEffect(() => {
    if (
      jobPosition &&
      currentPosition?.jobDescription &&
      mainResponsibilities &&
      currentPosition?.mainResponsabilities
    ) {
      if (
        draftToHtml(convertToRaw(jobPosition.getCurrentContent())) ===
          currentPosition?.jobDescription &&
        draftToHtml(convertToRaw(mainResponsibilities.getCurrentContent())) ===
          currentPosition.mainResponsabilities
      )
        return;
    }
    onFieldsChange();
  }, [
    currentPosition?.jobDescription,
    currentPosition?.mainResponsabilities,
    jobPosition,
    mainResponsibilities,
    onFieldsChange,
  ]);

  useEffect(() => {
    getSalesChannel();
  }, [getSalesChannel]);

  return (
    <>
      <TabFormPositionWrapper>
        {!isLoading ? (
          <TabForm
            defaultModify={formMode === FormModes.edit}
            confirmationMessage="timetracker.projectDetails.cancelConfirmMessage"
            requiredFields={requiredFields}
            showRequiredFieldText={false}
            tabFormConfig={tabFormConfig}
            actionsConfig={actionsConfig}
            saveButtonLabel="common.next"
            disableCallback={setIsTabDisabled}
            askForConfirmationOnCancel={hasChanged}
            onFieldsChange={onFieldsChange}
            disableSaveButton={!hasChanged}
          />
        ) : (
          <Spin />
        )}
      </TabFormPositionWrapper>
    </>
  );
};

export default NewPosition;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import AwardIcon from 'shared/images/award.svg';
import TerminalIcon from 'shared/images/terminal.svg';
import ComputerIcon from 'shared/images/computer.svg';
import { useTranslation } from 'react-i18next';
import { positionsService } from 'shared/services/positions.service';
import { Badge, Col, Row } from 'antd';
import { generateRandomId, showNotification } from 'shared/utils/common.utils';
import CommentsSection from 'components/Comments/CommentsSection';
import { useHistory } from 'react-router-dom';
import { PLACEMENT_PATH } from 'features/Routes/helpers';
import ViewPositionWrapper, {
  ButtonPillStars,
  HeaderDetails,
  HeaderText,
  HeaderViewPositionWrapper,
  Hr,
  PositionItemRightTitle,
  PositionItemSubElementWrapper,
  PositionItemTitle,
  PositionTitle,
  RateStyled,
  RightContentWrapper,
  SectionStyled,
  TextDescription,
} from './styles';
import { getPositionsDetails } from './helpers';
import InfoIcon from '../../AddPositions/NewPosition/Skills/Skill/InfoIcon';

interface PositionItemProps {
  position: DTO.Position;
}

const PositionView: FC<PositionItemProps> = ({ position }): JSX.Element => {
  const [pathToRedirect] = useState(PLACEMENT_PATH.POSITIONS);
  const [resetState] = useState(false);
  const [commentsData, setCommentsData] = useState<string>(
    position.comments || ''
  );
  const [skillCategories, setSkillCategories] = useState<
    DTO.PositionSkillCategory[]
  >([]);
  const [positionSkills, setPositionSkills] = useState<DTO.PositionSkill[][]>(
    []
  );
  const [positionType, setPositionType] = useState<
    DTO.PositionTypes | undefined
  >();
  const [countries, setCountries] = useState<Array<string>>([]);
  const iconStyle = { color: '#1890ff' };
  const { t } = useTranslation();
  const history = useHistory();
  const getPositionSkillLevels = useCallback(async (categoryId: number) => {
    try {
      const { result } = await positionsService.getPositionSkillLevel(
        categoryId
      );
      return result;
    } catch (e) {
      return [];
    }
  }, []);
  const getPositionSkillCategories = useCallback(async () => {
    try {
      const { results } = await positionsService.getPositionsSkillCategories();
      const levelsResult = await Promise.all(
        results.map((skill) => getPositionSkillLevels(skill.id))
      );
      const skillCategoriesAux = results.map((category) => ({
        ...category,
        skillLevels:
          levelsResult.find((level) =>
            level.find((level2) => level2.skillTypeId === category.id)
          ) || [],
      }));
      setSkillCategories(skillCategoriesAux);
      const skills = skillCategoriesAux.map((category) => {
        return position.openPositionSkills.filter(
          (skill) => skill.skillCategoryId === category.id
        );
      });
      setPositionSkills(skills);
    } catch (e) {
      setSkillCategories([]);
    }
  }, [getPositionSkillLevels, position.openPositionSkills]);

  const getCountries = useCallback(async () => {
    try {
      const countriesAvailable = await positionsService.getPositionCountry();
      const countryArray = position.positionAllocations.map((value) => {
        return value.countryAllocationId;
      });
      const countryList = countryArray.map((countryAux: string) => {
        const filterCountries: any = countriesAvailable.find(
          (c: any) => c.countryId === countryAux
        );
        return t(filterCountries.name);
      });
      setCountries(countryList);
    } catch (e) {
      setCountries([]);
    }
  }, [position.positionAllocations, t]);

  const getPositionType = useCallback(async () => {
    try {
      const { results } = await positionsService.getPlacementPositionTypes();
      setPositionType(
        results.find((type) => type.id === position.positionTypeId)
      );
    } catch (e) {
      setPositionType(undefined);
    }
  }, [position.positionTypeId]);

  const getData = useCallback(async () => {
    const fetchPositionType = getPositionType();
    const fetchCountries = getCountries();
    const fetchSkillCategories = getPositionSkillCategories();
    await Promise.allSettled([
      fetchPositionType,
      fetchCountries,
      fetchSkillCategories,
    ]);
  }, [getCountries, getPositionType, getPositionSkillCategories]);

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, [getData]);

  const handleAddComment = useCallback(
    async (comments: string) => {
      try {
        const data = {
          openPositionId: position.id,
          comments,
        };
        await positionsService.updatePositionComments(data);
        setCommentsData(comments);
        showNotification(t, {
          type: 'success',
          description: t('timetracker.comments.successfullySaved'),
        });
        return true;
      } catch (e) {
        showNotification(t, null, true);
        return false;
      }
    },
    [position.id, t]
  );

  const handleCancelComment = useCallback(() => {
    history.push(pathToRedirect);
  }, [history, pathToRedirect]);

  const renderSkillCategoryName = useCallback(
    (skills: DTO.PositionSkill[]) => {
      if (skills.length > 0) {
        const skillCategory = skillCategories.find(
          (category) => category.id === skills[0].skillCategoryId
        );
        return (
          <span>
            {skillCategory && (
              <InfoIcon
                key={skillCategory.name}
                skillType={skillCategory.name}
                skillTypeId={skillCategory.id}
                ratingNames={skillCategory.skillLevels}
              />
            )}
          </span>
        );
      }
      return '';
    },
    [skillCategories]
  );

  return (
    <>
      <HeaderViewPositionWrapper>
        <div className="head">
          <div className="head-description">
            <PositionTitle>{position.positionName}</PositionTitle>
            <div>
              <HeaderDetails>
                <img alt={positionType?.positionTypeName} src={ComputerIcon} />
                <HeaderText>{positionType?.positionTypeName}</HeaderText>
              </HeaderDetails>
              <HeaderDetails>
                <img alt={position.projectName} src={TerminalIcon} />
                <HeaderText>{position.projectName}</HeaderText>
              </HeaderDetails>
              <HeaderDetails>
                <img alt={position.seniority.name} src={AwardIcon} />
                <HeaderText>{position.seniority.name}</HeaderText>
              </HeaderDetails>
              <HeaderDetails>
                <UserOutlined style={iconStyle} />
                <HeaderText>{position.roleName}</HeaderText>
              </HeaderDetails>
            </div>
          </div>
        </div>
      </HeaderViewPositionWrapper>
      <ViewPositionWrapper>
        <Row justify="space-between">
          <Col span={18}>
            <SectionStyled>
              <div>
                <PositionItemTitle>
                  {t('positions.positionId')}
                </PositionItemTitle>
                <TextDescription>{position.id}</TextDescription>
              </div>
              <div>
                <PositionItemTitle>
                  {t('positions.jobDescription')}
                </PositionItemTitle>
                <TextDescription
                  dangerouslySetInnerHTML={{
                    __html: position.jobDescription,
                  }}
                />
              </div>
              <div>
                <PositionItemTitle>
                  {t('positions.mainResponsibilities')}
                </PositionItemTitle>
                <TextDescription
                  dangerouslySetInnerHTML={{
                    __html: position.mainResponsabilities,
                  }}
                />
              </div>
              {positionSkills.map((skills) => (
                <div key={generateRandomId()}>
                  <PositionItemTitle>
                    {renderSkillCategoryName(skills)}
                  </PositionItemTitle>
                  {skills.map((skill) => {
                    let skillLevels = [];
                    let rating = 0;
                    if (skill.skillLevelId !== undefined) {
                      skillLevels =
                        skillCategories.find(
                          (el) => el.id === skill.skillCategoryId
                        )?.skillLevels || [];
                      rating =
                        skillLevels.find((el) => el.id === skill.skillLevelId)
                          ?.score || 0;
                    }
                    return (
                      <Badge key={skill.id}>
                        <ButtonPillStars key={skill.id}>
                          {skill.skillName}
                          <RateStyled
                            count={skillLevels.length}
                            disabled
                            value={rating}
                          />
                        </ButtonPillStars>
                      </Badge>
                    );
                  })}
                </div>
              ))}
            </SectionStyled>
            <Hr color="#f0f0f0" />
            <CommentsSection
              resetState={resetState}
              onAddComment={handleAddComment}
              disableInput={!position.isActive}
              commentsData={commentsData}
              loading={false}
              cancelButton
              handleCancel={handleCancelComment}
              readonly
            />
          </Col>
          <Col span={5}>
            {getPositionsDetails(position, countries)
              .filter((el) => el.value !== '')
              .map((detail) => (
                <RightContentWrapper key={detail.label}>
                  {detail.icon}
                  <PositionItemSubElementWrapper>
                    <PositionItemRightTitle>
                      {t(detail.label)}:
                    </PositionItemRightTitle>
                    <TextDescription>{t(detail.value)}</TextDescription>
                  </PositionItemSubElementWrapper>
                </RightContentWrapper>
              ))}
          </Col>
        </Row>
      </ViewPositionWrapper>
    </>
  );
};

export default PositionView;

import styled from 'styled-components';
import { DatePicker } from 'antd';

export const DatePickerStyled = styled(DatePicker)`
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  width: 100%;
`;

export const RangePickerStyled = styled(DatePicker.RangePicker)`
  width: 100%;
`;
